import React from 'react'
import { Menu } from '@headlessui/react'
import { classNames } from '../../../../layouts/AppLayout/AppLayout'

export const MenuItem = ({ children, onClick = () => {} }: { children: React.ReactNode; onClick?: Function }) => (
    <Menu.Item>
        {({ active }) => (
            <span
                onClick={() => onClick()}
                className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer'
                )}
            >
                {children}
            </span>
        )}
    </Menu.Item>
)
