import React, { useState } from 'react'
import { FBORequestAvatar } from '../../shared/Avatar'
import { API } from '../../../lib/API'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { BUTTON_BASE_CLASSES } from '../../shared/Button'
import { PRIMARY_BUTTON_COLOR } from '../../../colors'
import { toast } from '../../../lib/toast'
import { ButtonLabelWithLoadingIcon } from '../../shared/ButtonLabelWithLoadingIcon'
import { Errors } from '../../shared/Errors'

export const NotesForm = () => {
    const [noteContent, setNoteContent] = useState('')
    const [errors, setErrors] = useState([])
    const params = useParams()

    const queryClient = useQueryClient()
    const mutation = useMutation(
        () => {
            if (!params.id) throw new Error('no params id')

            setErrors([])

            return API.createRequestNote({
                requestId: params.id,
                content: noteContent,
            })
        },
        {
            onSuccess: async (data) => {
                if (API.successfulResponse(data.status)) {
                    toast.success('Note added to request')
                    console.debug('invalidating notes')
                    setNoteContent('')
                    await queryClient.invalidateQueries(['request', params.id, 'notes'])
                    return
                }
                const responseJson = await data.json()
                setErrors(responseJson?.errors)
            },
        }
    )

    const updateNoteContent = (event: { target: { value: React.SetStateAction<string> } }) =>
        setNoteContent(event.target.value)

    const createNote = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        mutation.mutate()
    }

    return (
        <div className="py-4 rounded-lg">
            <Errors errors={errors} />
            <div className="flex space-x-3 py-4 border-t border-gray-200">
                <div className="flex-shrink-0">
                    <FBORequestAvatar />
                </div>
                <div className="min-w-0 flex-1">
                    <form onSubmit={createNote}>
                        <div>
                            <label htmlFor="note" className="sr-only">
                                About
                            </label>
                            <textarea
                                id="note"
                                name="note"
                                rows={3}
                                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                                placeholder="Add a note"
                                value={noteContent}
                                onChange={(event) => updateNoteContent(event)}
                            />
                        </div>
                        <div className="mt-3 flex items-center justify-end">
                            <button type="submit" className={`${BUTTON_BASE_CLASSES} ${PRIMARY_BUTTON_COLOR}`}>
                                <ButtonLabelWithLoadingIcon loading={mutation.isLoading}>
                                    Add Note
                                </ButtonLabelWithLoadingIcon>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
