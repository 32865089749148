import { useContext, useEffect, useState } from 'react'
import { TopBar } from '../../components/TopBar/TopBar'
import { AuthContext } from '../../contexts/AuthContext'
import { Sidebar } from './SideBar'

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

// TODO
//  -[] Break out sidebars
//  -[] DRY out mobile/desktop sidebars
export const AppLayout = () => {
    const auth = useContext(AuthContext)

    const [sidebarOpen, setSidebarOpen] = useState(false)

    useEffect(() => auth.restoreSession(), [auth])

    return (
        <>
            <div className="min-h-full" style={{ backgroundImage: 'url("/images/texture.svg")' }}>
                {auth.authenticated ? <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> : null}
                <TopBar setSidebarOpen={setSidebarOpen} />
            </div>
        </>
    )
}
