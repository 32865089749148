import DatePicker from 'react-datepicker'
import { parseJSON } from 'date-fns'
import { DATE_FORMAT, TIME_FORMAT } from '../../../shared/Time'
import React from 'react'

export const DateTimeInput = ({ value, updateValue }: { value: string; updateValue: Function }) => {
    const filterPassedTime = (time: any) => {
        const currentDate = new Date()
        const selectedDate = new Date(time)

        return currentDate.getTime() < selectedDate.getTime()
    }

    return (
        <div className="mt-1 border-b border-gray-300 focus:border-brand-primary-600">
            <DatePicker
                timeFormat="HH:mm"
                selected={value ? parseJSON(value) : null}
                minDate={new Date()}
                isClearable
                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-brand-primary-600 focus:ring-0 sm:text-sm"
                showTimeSelect
                filterTime={filterPassedTime}
                dateFormat={`${DATE_FORMAT} ${TIME_FORMAT}`}
                timeIntervals={15}
                strictParsing
                onChange={(date) => updateValue(date)}
            />
        </div>
    )
}
