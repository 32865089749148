import Cookies from 'universal-cookie'
import { httpMethods } from '../types/API/httpMethods'
import { UpdateProfile } from '../types/API/UpdateProfile'
import { httpOpts } from '../types/API/httpOpts'
import { RequestUpdateType } from '../types/API/RequestUpdateType'
import { SignUpParams } from '../types/API/SignUpParams'

const cookies = new Cookies()

type Endpoint = string | string[]

export function API() {}

API.headers = () => {
    let headers = new Headers()
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    if (API.token()) headers.set('Authorization', 'Bearer ' + API.token())

    return headers
}

API.token = () => cookies.get('token')
API.hostname = (v2 = false) => {
    const PROD_API_HOST = 'api.fborequest.com'
    const STAGING_API_HOST = 'api.staging.fborequest.com'
    const LOCAL_API_HOST = v2 ? 'localhost:4000' : 'localhost:3001'

    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (API.development() && API.useLocalhost()) return LOCAL_API_HOST
    if (API.development()) return STAGING_API_HOST
    if (API.staging()) return STAGING_API_HOST
    if (API.production()) return PROD_API_HOST
    throw new Error(
        `Unable to set host. env: ${API.environment()}; staging: ${API.staging()}; host: ${window.location.host}`
    )
}

API.environment = () => process.env.NODE_ENV
API.production = () => API.environment() === 'production'
API.staging = () =>
    window.location.host === 'beta.staging.fborequest.com' || window.location.host === 'staging.fborequest.com'
API.development = () => API.environment() === 'development'
API.useLocalhost = () => API.development() && process.env.REACT_APP_USE_LOCALHOST === 'true'

API.url = (v2 = false) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (API.useLocalhost()) return `http://${API.hostname(v2)}`
    if (API.production()) return `https://${API.hostname(v2)}`
    return `https://${API.hostname()}`
}
API.apiUrl = (endpoint: string | string[], v2 = false) => {
    const API_PATH = v2 ? '/api/v2/' : '/api/v1/'

    if (typeof endpoint === 'string') {
        return API.url(v2) + API_PATH + endpoint
    }
    return API.url(v2) + API_PATH + endpoint.join('/')
}

API.send = async (endpoint: Endpoint, data: Object | null, method: httpMethods, v2 = false) => {
    let options: httpOpts = {
        method: method,
        headers: API.headers(),
    }

    if (data) {
        options['body'] = JSON.stringify(data)
    }
    return await fetch(API.apiUrl(endpoint, v2), options).then((response) => {
        if (response.ok) {
            return response
        }
        if (response.status === 401) {
            window.location.href = '/'
        }
        throw new Error(response.statusText)
    })
}

API.get = async (endpoint: Endpoint, v2 = false) => {
    return await API.send(endpoint, null, 'GET', v2)
}

API.post = async (endpoint: Endpoint, data: Object, v2 = false) => {
    return await API.send(endpoint, data, 'POST', v2)
}

API.patch = async (endpoint: Endpoint, data: Object, v2 = false) => {
    return await API.send(endpoint, data, 'PATCH', v2)
}

API.signIn = (data: { email: string; password: string }) => API.post(['users', 'sign_in'], { user: data }, true)
API.requests = () => API.get(['requests', 'all'])
API.todaysRequests = () => API.get(['requests', 'today'])
API.request = (id: string) => API.get(['requests', id])
API.updateRequest = (request: RequestUpdateType) => API.patch(['requests', request.id], request)
API.requestNotes = (id: string) => API.get(['requests', id, 'notes'])
API.createRequestNote = ({ requestId, content }: { requestId: string; content: string }) =>
    API.post(['requests', requestId, 'notes'], { content: content })

API.acknowledge = (id: string) =>
    API.patch(['requests', id, 'acknowledge'], {
        request: { id: id },
    })
API.complete = (id: string) => API.patch(['requests', id, 'complete'], { request: { id: id } })
API.reject = (id: string) => API.patch(['requests', id, 'reject'], { request: { id: id } })
API.showOnDashboard = (id: string) => API.patch(['requests', id, 'add_to_dashboard'], { request: { id: id } })
API.hideOnDashboard = (id: string) => API.patch(['requests', id, 'clear_from_dashboard'], { request: { id: id } })

API.aircraft = () => {
    // serialized param can be removed once react UI is fully deployed
    if (!API.token()) {
        Promise.resolve('not logged in')
        return
    }
    return API.get('aircraft?serialized=true')
}

API.addAircraft = ({ tailNumber, userId }: { tailNumber: string; userId: string }) =>
    API.post(['aircraft'], { tail_number: tailNumber, user_id: userId })

API.resetPassword = (email: string) => API.post(['users', 'password'], { user: { email: email } })

API.changePassword = (password: string, password_confirmation: string, token: string | null) =>
    API.patch(['users', 'password'], {
        user: {
            password: password,
            password_confirmation: password_confirmation,
            reset_password_token: token,
        },
    })

API.signUp = ({ email, password, passwordConfirmation }: SignUpParams) =>
    API.post(['users'], {
        user: {
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
        },
    })

API.profile = () => API.get(['users', 'profile'])

API.updateProfile = ({ ...values }: UpdateProfile) => API.patch(['users', 'profile'], { user: values })
API.successfulResponse = (responseStatus: number) => responseStatus >= 200 && responseStatus < 300

API.fuelTrucks = () => API.get(['fuel_trucks'], true)
API.fuelRequests = () => API.get(['services', 'fuel_request'], true)
API.metar = () => API.get(['metar?id=kisp'], true)
