import { Route, Routes } from 'react-router-dom'
import { AppLayout } from './layouts/AppLayout/AppLayout'
import { RouteContainer } from './components/RouteContainer'
import { TodaysRequests } from './components/Requests/TodaysRequests'
import { NewRequest } from './components/NewRequest/NewRequest'
import { Request } from './components/Request/Request'
import { Settings } from './components/Settings/Settings'
import { SignIn } from './components/SignIn'
import { FourOhFour } from './components/FourOhFour'
import React, { useContext } from 'react'
import { Dashboard } from './components/Dashboard/Dashboard'
import { AuthContext } from './contexts/AuthContext'
import { ForgotPassword } from './components/ForgotPassword'
import { SignUp } from './components/SignUp'
import { Profile } from './components/Profile/Profile'
import { Submitted } from './components/NewRequest/Submitted'
import { AllRequests } from './components/Requests/AllRequests'
import { Support } from './components/Support'
import { Aircraft } from './components/Aircraft'
import { Home } from './components/Home/Home'
import { Reports } from './components/Reports'
import { FuelTrucks } from './components/FuelTrucks/FuelTrucks'
import { FuelTruck } from './components/FuelTrucks/FuelTruck'
import { NewFuelTruck } from './components/FuelTrucks/NewFuelTruck'
import { ResetPassword } from './components/ResetPassword'
import { AllFuelRequests } from './components/FuelRequests/AllFuelRequests'

export const FBORequestRoutes = () => (
    <Routes>
        <Route element={<AppLayout />}>
            <Route path="/" element={<RouteContainer />}>
                <Route index element={<HomePage />} />

                <Route path="requests" element={<RouteContainer />}>
                    <Route index element={<TodaysRequests />} />
                    <Route path="all" element={<AllRequests />} />

                    <Route path="today" element={<RouteContainer />}>
                        <Route index element={<TodaysRequests />} />
                        <Route path="arrivals" element={<TodaysRequests />} />
                        <Route path="departures" element={<TodaysRequests />} />
                    </Route>

                    <Route path=":id" element={<Request />} />

                    <Route path="new" element={<RouteContainer />}>
                        <Route index element={<NewRequest />} />
                        <Route path="submitted" element={<Submitted />} />
                    </Route>
                </Route>
            </Route>
            <Route path="/aircraft" element={<Aircraft />} />

            <Route path="fuel" element={<RouteContainer />}>
                <Route index element={<AllFuelRequests />} />
                <Route path="requests" element={<AllFuelRequests />} />

                <Route path="trucks" element={<FuelTrucks />} />
                <Route path="trucks/:id" element={<FuelTruck />} />
                <Route path="trucks/new" element={<NewFuelTruck />} />
            </Route>

            <Route path="/settings" element={<Settings />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/support" element={<Support />} />

            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
        </Route>

        {/* Full screen (ie routes without the header/sidebar) come here */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/sign_in" element={<SignIn />} />
        <Route path="/sign_up" element={<SignUp />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/users/password/edit" element={<ResetPassword />} />
        <Route path="*" element={<FourOhFour />} />
    </Routes>
)

const HomePage = () => {
    const auth = useContext(AuthContext)

    if (!auth || !auth.authenticated) {
        return <NewRequest />
    }

    return <Home employee={auth.user ? auth?.user?.data?.attributes?.employee : false} />
}
