import React from 'react'
import { FBORequestAvatar } from '../../shared/Avatar'
import { API } from '../../../lib/API'
import { useParams } from 'react-router-dom'
import { Time } from '../../shared/Time'
import { Content } from '../../shared/Content'
import { useQuery } from 'react-query'
import { NoteType } from '../../../types/NoteType'
import { NotesForm } from './NotesForm'
import { EmployeeOnly } from '../../shared/EmployeeOnly'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

export const Notes = () => {
    const params = useParams()

    const fetchRequestNotes = async () => {
        console.log('fetching notes for request ' + params.id)
        if (!params.id) {
            return
        }
        const data = await API.requestNotes(params.id)
        return await data.json()
    }

    const { data, error, isError, isLoading } = useQuery(['request', params.id, 'notes'], fetchRequestNotes)

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }

    if (data.error) {
        return <div>Error! {data.error}</div>
    }

    return (
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <div className="bg-white shadow sm:rounded-lg">
                <Content.Section.Header>
                    <Content.Section.Header.Title>Notes</Content.Section.Header.Title>
                    <EmployeeOnly>
                        <Content.Section.Header.Subtitle>
                            <div className="flex">
                                <ExclamationCircleIcon className="h-5 w-auto text-yellow-400 pr-1" /> Notes are visible
                                to customers
                            </div>
                        </Content.Section.Header.Subtitle>
                    </EmployeeOnly>
                </Content.Section.Header>
                <Content.Section.Body>
                    <ul className="space-y-6">
                        {data.map((note: NoteType) => (
                            <li key={note.data.id}>
                                <div className="flex space-x-3">
                                    <div className="flex-shrink-0">
                                        <FBORequestAvatar name={note.data.attributes.user_avatar} />
                                    </div>
                                    <div>
                                        <div className="text-sm">
                                            <span className="text-sm text-gray-500">
                                                <Time>{note.data.attributes.created_at}</Time> by{' '}
                                                {note.data.attributes.user_name}
                                            </span>
                                        </div>
                                        <div className="mt-0 text-gray-700">
                                            <p>{note.data.attributes.content}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <NotesForm />
                </Content.Section.Body>
            </div>
        </div>
    )
}
