import { CheckCircleIcon } from '@heroicons/react/outline'

const DEFAULT_TITLE = 'Successfully saved!'

export const SuccessToast = ({ title = DEFAULT_TITLE, body }: { title?: string; body?: string }) => (
    <div className="flex items-start">
        <div className="flex-shrink-0">
            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-500">{body}</p>
        </div>
    </div>
)
