import React from 'react'
import { Section } from './Content/Section'
import { Header } from './Content/Header/Header'
import { ReactChildrenProp } from '../../types/ReactChildrenProp'

export const Content = ({ children }: { children: React.ReactNode }) => <main>{children}</main>

const Container = ({ children }: ReactChildrenProp) => (
    <div className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 mb-8">
        {children}
    </div>
)

export const ColumnSection = ({ children }: ReactChildrenProp) => (
    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
        <section aria-labelledby="notes-title">
            <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">{children}</div>
        </section>
    </div>
)

const LeftColumn = ({ children }: ReactChildrenProp) => (
    <div className="space-y-6 lg:col-start-1 lg:col-span-2">{children}</div>
)

const RightColumn = ({ children }: ReactChildrenProp) => (
    <section aria-labelledby="right-column" className="lg:col-start-3 lg:col-span-1">
        {children}
    </section>
)

Content.Header = Header
Content.Section = Section
Content.Container = Container
Content.ColumnSection = ColumnSection
Content.LeftColumn = LeftColumn
Content.RightColumn = RightColumn

// Usage:
//  <Content>
//      <Content.Header>
//          <Content.Header.Title>
//              Some title
//          </Content.Header.Title>
//      </Content.Header>
//
//      <Content.Section>
//          <Content.Section.Header>
//              <Content.Section.Header.Title>Requests by Day Of Week</Content.Section.Header.Title>
//              <Content.Section.Header.Subtitle>All time</Content.Section.Header.Subtitle>
//      </Content.Section.Header>
//      <Content.Section.Body>
//      </Content.Section.Body>
//  <Content />
