import { ExclamationCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import { capitalizeFirstLetter } from '../../lib/capitalizeFirstLetter'

// A component meant to display errors from an API request.
// Expects an array of errors returned by the API
// Renders red text with the contents of the error
export const Errors = ({ errors }: { errors: string[] }) => {
    if (errors === undefined) errors = ['An error has occurred']
    if (errors.length === 0) return null
    return (
        <div className="mb-2">
            <ul className="list-inside">
                {errors.map((error, index) => (
                    <span key={index} className="flex align-middle items-center">
                        <ExclamationCircleIcon className="h-3 w-3 text-red-600 mr-1" />
                        <li className="text-red-600">{error}</li>
                    </span>
                ))}
            </ul>
        </div>
    )
}

// A component meant to display errors from a Phoenix API request.
// Expects an object of errors with their messages
// Renders red text with the contents of the error
export const PhxErrors = ({ errors }: { errors: Object }) => {
    if (Object.keys(errors).length === 0) return null

    return (
        <div className="mb-2">
            <ul className="list-inside">
                {Object.entries(errors).map(([key, value]) => (
                    <span key={key} className="flex align-middle items-center">
                        <ExclamationCircleIcon className="h-3 w-3 text-red-600 mr-1" />
                        <li className="text-red-600">
                            {capitalizeFirstLetter(key)} {value}
                        </li>
                    </span>
                ))}
            </ul>
        </div>
    )
}
