import { useCallback, useContext, useEffect, useState } from 'react'
import { API } from '../../lib/API'
import { loadRequests, subscribeMessage } from '../../lib/ActionCableMessages'
import { ActionCableMessage } from '../../types/ActionCableMessage'
import { RequestType } from '../../types/RequestType'
import { Header } from './Header'
import {
    compareDesc,
    differenceInMilliseconds,
    endOfToday,
    isFuture,
    isToday,
    isTomorrow,
    parseISO,
    secondsToMilliseconds,
} from 'date-fns'
import { DashboardPane } from './DashboardPane'
import useWebSocket from 'react-use-websocket'
import { AuthContext } from '../../contexts/AuthContext'

export const Dashboard = () => {
    const [todaysRequests, setTodaysRequests] = useState<RequestType[]>([])
    const [tomorrowsRequests, setTomorrowsRequests] = useState<RequestType[]>([])
    const [futureRequests, setFutureRequests] = useState<RequestType[]>([])
    const [midnightUpdateTimeout, setMidnightUpdateTimeout] = useState<number>()
    const [subscribed, setSubscribed] = useState(false)
    const [subscribing, setSubscribing] = useState(false)
    const auth = useContext(AuthContext)

    const webSocketUrl = useCallback(() => {
        console.log('Fetching websocket url')
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if (API.useLocalhost()) return `ws://${API.hostname()}/cable`
        if (API.production()) return `wss://${API.hostname()}/cable`
        return `wss://${API.hostname()}/cable`
    }, [])

    const subscribeToRequestsChannel = () => {
        if (subscribing || subscribed) return

        if (auth.user) {
            setSubscribing(true)
            console.debug('Subscribing to requests channel')
            sendMessage(subscribeMessage({ id: auth?.user?.data?.id }))
        }
        setSubscribing(false)
    }

    // Without these the websocket wasn't sending the subscription request
    useEffect(() => auth.restoreSession(), [auth])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => subscribeToRequestsChannel(), [auth.user])
    const { sendMessage, lastJsonMessage, readyState } = useWebSocket(webSocketUrl, {
        onOpen: subscribeToRequestsChannel,
        shouldReconnect: () => true,
    })

    const updateAtMidnight = useCallback(() => {
        const timeNow = new Date()
        const timeToMidnight = differenceInMilliseconds(endOfToday(), timeNow) + secondsToMilliseconds(1)

        console.debug('Loaded requests at ' + timeNow)
        console.debug(`Reloading requests in ${timeToMidnight} ms`)

        if (midnightUpdateTimeout) {
            console.debug(`Clearing interval id ${midnightUpdateTimeout}`)
            clearTimeout(midnightUpdateTimeout)
        }

        const newTimeout = window.setTimeout(() => {
            if (!auth.user) return
            sendMessage(loadRequests(auth?.user?.data?.id))
        }, timeToMidnight)

        setMidnightUpdateTimeout(newTimeout)
        console.debug(`Created interval id ${newTimeout}`)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendMessage, auth.user])

    useEffect(() => {
        const thisMessage = lastJsonMessage as unknown as ActionCableMessage
        if (!thisMessage) return

        if (thisMessage.type === 'ping' || thisMessage.type === 'welcome') {
            return
        }

        if (thisMessage?.type === 'confirm_subscription') {
            setSubscribed(true)
            if (!auth.user) return
            sendMessage(loadRequests(auth?.user?.data?.id))
            return
        }

        if (thisMessage?.message?.type === 'requests') {
            updateAtMidnight()

            const sortedRequests = thisMessage.message.payload.requests.sort((a, b) =>
                compareDesc(parseISO(a.data.attributes.earliest_time), parseISO(b.data.attributes.earliest_time))
            )

            setTodaysRequests([])
            setTomorrowsRequests([])
            setFutureRequests([])

            sortedRequests.forEach((request) => {
                if (requestToday(request)) {
                    if (request.data.attributes.status === 'completed') {
                        setTodaysRequests((currentState) => [...currentState, request])
                    } else {
                        setTodaysRequests((currentState) => [request, ...currentState])
                    }
                }
            })

            sortedRequests.forEach((request) => {
                if (requestTomorrow(request)) {
                    if (request.data.attributes.status === 'completed') {
                        setTomorrowsRequests((currentState) => [...currentState, request])
                    } else {
                        setTomorrowsRequests((currentState) => [request, ...currentState])
                    }
                }
            })

            sortedRequests.forEach((request) => {
                if (requestFuture(request)) {
                    if (request.data.attributes.status === 'completed') {
                        setFutureRequests((currentState) => [...currentState, request])
                    } else {
                        setFutureRequests((currentState) => [request, ...currentState])
                    }
                }
            })
            return
        }
        console.debug('Unhandled message')
        console.log(thisMessage)
    }, [lastJsonMessage, sendMessage, auth.user, updateAtMidnight])

    return (
        <>
            <Header readyState={readyState} />
            <DashboardPane requests={todaysRequests} />
            <DashboardPane requests={tomorrowsRequests} label="Tomorrow's Requests" />
            <DashboardPane requests={futureRequests} label="Future Requests" />
        </>
    )
}

const requestToday = (request: RequestType) => {
    if (isToday(parseISO(request.data.attributes.stage_time))) {
        return true
    }

    if (isToday(parseISO(request.data.attributes.departure_time))) {
        return true
    }

    return isToday(parseISO(request.data.attributes.arrival_time))
}

const requestTomorrow = (request: RequestType) => {
    if (isTomorrow(parseISO(request.data.attributes.stage_time))) {
        return true
    }

    if (isTomorrow(parseISO(request.data.attributes.departure_time))) {
        return true
    }

    return isTomorrow(parseISO(request.data.attributes.arrival_time))
}

const requestFuture = (request: RequestType) => {
    // We don't want to return requests that already show up in the 'Today' or 'Tomorrow' pane
    if (requestToday(request) || requestTomorrow(request)) {
        return false
    }

    if (isFuture(parseISO(request.data.attributes.stage_time))) {
        return true
    }

    if (isFuture(parseISO(request.data.attributes.departure_time))) {
        return true
    }

    return isFuture(parseISO(request.data.attributes.arrival_time))
}

// Webpack wasn't including the classes with ANY kind of interpolation
// so we have to be a little repetitive
export const borderColor = (status: string) => {
    switch (status) {
        case 'rejected':
            return 'border-t-red-400'
        case 'new':
            return 'border-t-yellow-400'
        case 'acknowledged':
            return 'border-t-brand-primary-400'
        case 'completed':
            return 'border-t-green-600'
    }
}

export const statusIconColor = (status: string) => {
    switch (status) {
        case 'rejected':
            return 'text-red-400'
        case 'new':
            return 'text-yellow-400'
        case 'acknowledged':
            return 'text-brand-primary-400'
        case 'completed':
            return 'text-green-600'
    }
}
