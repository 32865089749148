import { API } from '../lib/API'
import { useQuery } from 'react-query'

export const useProfile = () => {
    const fetchProfile = async () => {
        console.log('Fetching profile')
        const data = await API.profile()
        return await data.json()
    }

    return useQuery(['users', 'profile'], fetchProfile)
}
