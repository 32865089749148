import { AuthenticatedButtons } from './UpperBar/AuthenticatedButtons'
import { UnauthenticatedButtons } from './UpperBar/UnauthenticatedButtons'
import { PrimaryButton } from '../shared/PrimaryButton'
import { NavLink } from 'react-router-dom'

export const UpperBar = ({ authenticated = false, fullName }: { authenticated: boolean; fullName: string }) => (
    <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="ml-4 flex w-full justify-end items-center md:ml-6">
            <div className="mt-6 flex space-x-4 md:mt-0 md:ml-4 md:mr-3">
                <NavLink to="/requests/new">
                    <PrimaryButton>New Request</PrimaryButton>
                </NavLink>
            </div>

            {authenticated ? <AuthenticatedButtons fullName={fullName} /> : <UnauthenticatedButtons />}
        </div>
    </div>
)
