import React, { useEffect, useState } from 'react'
import { ProfileForm } from './ProfileForm'
import { useProfile } from '../../hooks/useProfile'

export const Profile = () => {
    const { data: user, error, isError, isLoading } = useProfile()

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const updateEmail = (event: { target: { value: any } }) => setEmail(event.target.value)
    const updateFirstName = (event: { target: { value: any } }) => setFirstName(event.target.value)
    const updateLastName = (event: { target: { value: any } }) => setLastName(event.target.value)
    const updatePhoneNumber = (event: { target: { value: any } }) => setPhoneNumber(event.target.value)

    const formValues = {
        email: email,
        updateEmail: updateEmail,
        firstName: firstName,
        updateFirstName: updateFirstName,
        lastName: lastName,
        updateLastName: updateLastName,
        phoneNumber: phoneNumber,
        updatePhoneNumber: updatePhoneNumber,
    }

    useEffect(() => {
        setEmail(user?.data.attributes.email)
        setFirstName(user?.data.attributes.first_name)
        setLastName(user?.data.attributes.last_name)
        setPhoneNumber(user?.data.attributes.phone_number)
    }, [user])

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }

    if (user.error || !user.data) {
        return <div>Error! {user.error}</div>
    }

    return (
        <main>
            {/* Page header */}
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Profile</h1>
                    </div>
                </div>
            </div>
            <div className="mt-2 max-w-8xl mx-auto grid grid-cols-8 sm:px-6 lg:max-w-8xl lg:grid-flow-col-dense lg:grid-cols-1">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            {user ? <ProfileForm {...formValues} /> : <span>'Loading...'</span>}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
