import { Content } from '../shared/Content'
import { useFuelTrucks } from '../../hooks/useFuelTrucks'
import { Link } from 'react-router-dom'
import { FuelTruckData } from '../../types/FuelTruck'
import { NoContent } from '../shared/NoContent'
import { PlusCircleIcon } from '@heroicons/react/outline'

export const capacityPerCent = (level: number, capacity: number) => Math.round((level / capacity) * 100)

export const FuelTrucks = () => {
    const { data: fuelTrucks, isLoading } = useFuelTrucks()

    if (isLoading) return <div>Loading...</div>

    return (
        <Content>
            <Content.Header>
                <Content.Header.Title>
                    Fuel Trucks
                    <Link
                        to="/fuel/trucks/new"
                        className="text-brand-primary hover:text-brand-primary-700 text-xs self-center pl-2"
                    >
                        <PlusCircleIcon className="h-4 w-auto self-center" />
                    </Link>
                </Content.Header.Title>
            </Content.Header>
            <Content.Section>
                <table className="min-w-full divide-y divide-gray-300 table-fixed">
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {fuelTrucks.data.length === 0 ? (
                            <NoContent>no fuel trucks</NoContent>
                        ) : (
                            fuelTrucks?.data.map((fuelTruck: FuelTruckData) => (
                                <tr>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-1/3">
                                        <Link to={`/fuel/trucks/${fuelTruck?.id}`}>{fuelTruck.attributes.name}</Link>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {fuelTruck.attributes.level}/{fuelTruck.attributes.capacity} gallons of{' '}
                                        {fuelTruck.attributes.type}
                                        <div className="relative pt-1">
                                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-brand-primary-200">
                                                <div
                                                    style={{
                                                        width: `${capacityPerCent(
                                                            fuelTruck.attributes.level,
                                                            fuelTruck.attributes.capacity
                                                        )}%`,
                                                    }}
                                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-brand-primary-500"
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </Content.Section>
        </Content>
    )
}
