import { SideMenuItemLinkWrapper } from './SideMenuItemLinkWrapper'
import { NavigationItemsType } from '../../types/NavigationMenuItems'
import { ExternalLinkIcon } from '@heroicons/react/outline'
import { EmployeeOnly } from '../shared/EmployeeOnly'

export const SideMenuItem = ({ item }: { item: any }) => {
    if (item?.show === false) {
        return null
    }

    return (
        <>
            <EmployeeOnly employeeOnly={item.employeeOnly === undefined ? false : item.employeeOnly}>
                <SideMenuItemLinkWrapper item={item}>
                    <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true" />
                    {item.name}
                    {item?.externalLink ? (
                        <ExternalLinkIcon className="ml-2 flex-shrink-0 h-4 w-4 text-cyan-200" aria-hidden="true" />
                    ) : null}
                </SideMenuItemLinkWrapper>
            </EmployeeOnly>

            {item.subMenuItems
                ? item.subMenuItems.map((subItem: NavigationItemsType): any => (
                      <EmployeeOnly employeeOnly={item.employeeOnly === undefined ? false : item.employeeOnly}>
                          <SideMenuItemLinkWrapper item={subItem} indent={true}>
                              - {subItem.name}
                          </SideMenuItemLinkWrapper>
                      </EmployeeOnly>
                  ))
                : null}
        </>
    )
}
