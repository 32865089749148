import { FuelTruck } from '../../types/FuelTruck'
import { ShowCard } from '../shared/ShowCard'
import React from 'react'

export const FuelTruckShow = ({ fuelTruck }: { fuelTruck: FuelTruck }) => (
    <>
        <ShowCard.Item>
            <ShowCard.Item.Name>Name</ShowCard.Item.Name>
            <ShowCard.Item.Value>{fuelTruck?.data.attributes.name}</ShowCard.Item.Value>
        </ShowCard.Item>
        <ShowCard.Item>
            <ShowCard.Item.Name>Type</ShowCard.Item.Name>
            <ShowCard.Item.Value>{fuelTruck?.data.attributes.type}</ShowCard.Item.Value>
        </ShowCard.Item>
        <ShowCard.Item>
            <ShowCard.Item.Name>Meter</ShowCard.Item.Name>
            <ShowCard.Item.Value>{fuelTruck?.data.attributes.meter}</ShowCard.Item.Value>
        </ShowCard.Item>
        <ShowCard.Item>
            <ShowCard.Item.Name>Level</ShowCard.Item.Name>
            <ShowCard.Item.Value>{fuelTruck?.data.attributes.level}</ShowCard.Item.Value>
        </ShowCard.Item>
        <ShowCard.Item>
            <ShowCard.Item.Name>Capacity</ShowCard.Item.Name>
            <ShowCard.Item.Value>{fuelTruck?.data.attributes.capacity}</ShowCard.Item.Value>
        </ShowCard.Item>
    </>
)
