import React from 'react'
import ClockLoader from 'react-spinners/ClockLoader'

const DEFAULT_ICON_SIZE = 18

interface ButtonLabelWithLoadingIconProps {
    loading: boolean
    icon: any
    children: React.ReactNode
    size?: number
    color?: string
    iconInFront?: boolean
    iconClass?: string
}

export const ButtonLabelWithReplacementLoadingIcon = ({
    loading,
    children,
    size = DEFAULT_ICON_SIZE,
    color = '#fff',
    iconInFront = false,
    iconClass,
    icon,
}: ButtonLabelWithLoadingIconProps) => {
    const iconPadding = iconInFront ? 'mr-1' : 'ml-1'
    if (!iconClass) iconClass = `${iconPadding} flex h-5 w-5 text-white group-hover:text-gray-500`

    return (
        <>
            {iconInFront ? null : children}
            {loading ? (
                <ClockLoader loading={loading} color={color} size={size} className={`${iconPadding} w-full`} />
            ) : (
                React.createElement(icon, {
                    className: iconClass,
                    'aria-hidden': 'true',
                })
            )}
            {iconInFront ? children : null}
        </>
    )
}
