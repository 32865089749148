import { QueryClient, useMutation } from 'react-query'
import { API } from './API'
import { toast } from './toast'

export type RequestActionType = 'acknowledge' | 'complete' | 'reject' | 'showOnDashboard' | 'hideOnDashboard'

interface MutationProps {
    action: RequestActionType
    id: string
}
export const requestActionMutation = (queryClient: QueryClient) => {
    return (
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useMutation(
            ({ action, id }: MutationProps) => {
                if (action === 'acknowledge') return API.acknowledge(id)
                if (action === 'complete') return API.complete(id)
                if (action === 'reject') return API.reject(id)
                if (action === 'showOnDashboard') return API.showOnDashboard(id)
                if (action === 'hideOnDashboard') return API.hideOnDashboard(id)
                throw new Error(`Unknown action '${action}'`)
            },
            {
                onSuccess: async (response, { id }: { id: string }) => {
                    if (API.successfulResponse(response.status)) {
                        toast.success('Request has been updated')
                        console.log('invalidating request ' + id)
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        queryClient.invalidateQueries(['request', id.toString()])
                    } else {
                        toast.error('Request has not been updated')
                    }
                },
            }
        )
    )
}
