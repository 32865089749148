import React from 'react'
import { PRIMARY_BUTTON_COLOR } from '../../colors'
import { BUTTON_BASE_CLASSES } from './Button'

export const PrimaryButton = (
    props: JSX.IntrinsicAttributes &
        React.ClassAttributes<HTMLButtonElement> &
        React.ButtonHTMLAttributes<HTMLButtonElement>
) => (
    <button type="button" className={`${BUTTON_BASE_CLASSES} ${PRIMARY_BUTTON_COLOR} self-start px-4 py-2`} {...props}>
        {props.children}
    </button>
)
