import { Link } from 'react-router-dom'
import { Time } from '../shared/Time'
import React from 'react'
import { Card } from '../shared/Card'

const TH_STYLE = 'text-center px-6 py-3 bg-gray-50 text-xs font-medium text-gray-500 uppercase'
const TD_STYLE = 'text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500'

export const FuelRequestsTable = ({ data }: { data: [] }) => (
    <Card>
        <table className="min-w-full divide-y divide-gray-200">
            <thead>
                <tr>
                    <th className={TH_STYLE} scope="col">
                        Aircraft
                    </th>
                    <th className={TH_STYLE} scope="col">
                        Meter End
                    </th>
                    <th className={TH_STYLE} scope="col">
                        Meter Start
                    </th>
                    <th className={TH_STYLE} scope="col">
                        Quantity
                    </th>
                    <th className={TH_STYLE} scope="col">
                        Status
                    </th>
                </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
                {data.map((request: any) =>
                    request ? (
                        <tr key={request.data.attributes.id} className="bg-white">
                            <td className={TD_STYLE}>
                                <Link
                                    to={'/requests/' + request.data.attributes.id}
                                    state={{ request: request }}
                                    className="hover:text-gray-700"
                                >
                                    {request.data.attributes.tail_number}
                                </Link>
                            </td>
                            <td className={TD_STYLE}>
                                <Time>{request.data.attributes.stage_time}</Time>
                            </td>
                            <td className={TD_STYLE}>
                                <Time>{request.data.attributes.departure_time}</Time>
                            </td>
                            <td className={TD_STYLE}>
                                <Time>{request.data.attributes.arrival_time}</Time>
                            </td>
                            <td className={TD_STYLE}>
                                <StatusLabel status={request.data.attributes.status} />
                            </td>
                        </tr>
                    ) : null
                )}
            </tbody>
        </table>
    </Card>
)

const StatusLabel = ({ status }: { status: string }) => {
    interface LabelColor {
        [key: string]: string
    }

    const labelColor: LabelColor = {
        new: 'bg-yellow-100',
        acknowledged: 'bg-brand-primary-100',
        completed: 'bg-green-100',
        rejected: 'bg-red-100',
    }

    return (
        <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${labelColor[status]}`}>
            {status}
        </span>
    )
}
