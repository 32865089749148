import { CardTitle } from '../shared/CardTitle'
import React from 'react'
import { API } from '../../lib/API'
import { useQuery } from 'react-query'
import { RequestsTable } from './RequestsTable'

export const TodaysRequests = () => {
    const fetchRequests = async () => {
        console.log('fetching requests')
        const data = await API.todaysRequests()
        const responseJson = await data.json()
        return responseJson
    }

    const { data, error, isError, isLoading } = useQuery('requests', fetchRequests)

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }
    if (data.error) {
        return null
    }

    return (
        <>
            <CardTitle>Today's Requests</CardTitle>
            <RequestsTable data={data} />
        </>
    )
}
