import { MenuItem } from './MenuItem'
import { CheckCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import { ActionProps } from './Acknowledge'

export const Complete = ({ status, id, mutation }: ActionProps) => {
    if (status === 'acknowledged') {
        return (
            <MenuItem onClick={() => mutation.mutate({ action: 'complete', id: id })}>
                <CheckCircleIcon
                    className="mr-3 h-5 w-5 text-green-500 group-hover:text-green-600"
                    aria-hidden="true"
                />
                Complete
            </MenuItem>
        )
    }

    return null
}
