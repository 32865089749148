import { MenuSection } from './MenuSection'
import React from 'react'
import { Reject } from './Reject'
import { ActionProps } from './Acknowledge'

export const RejectableStatuses = ({ status, id, mutation }: ActionProps) => {
    if (status === 'new' || status === 'acknowledged') {
        return (
            <MenuSection>
                <Reject status={status} id={id} mutation={mutation} />
            </MenuSection>
        )
    }

    return null
}
