import { API } from '../lib/API'
import { useQuery } from 'react-query'
import React from 'react'
import { Content } from './shared/Content'
import { ChartBarIcon } from '@heroicons/react/outline'
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, PointElement, LineElement)

const barChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
    },
}

const lineChartOptions = {
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    stacked: false,
    scales: {
        yAxis: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
        },
    },
}

const monthLineChartOptions = {
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Nov', 'Dec'],
    scales: {
        yAxis: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
        },
        x: {
            title: {
                display: true,
                label: 'Day',
            },
        },
    },
}

export const Reports = () => {
    const fetchRollupStats = async () => {
        console.log('fetching stats')
        const data = await API.get(['reports', 'requests'])
        return await data?.json()
    }

    const { data, error, isError, isLoading } = useQuery(['reports', 'requests'], fetchRollupStats)

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }
    if (data.error) {
        return null
    }

    return (
        <Content>
            <Content.Header>
                <Content.Header.Title>
                    <ChartBarIcon className="h-8 mr-2 w-auto" /> Reports
                </Content.Header.Title>
            </Content.Header>

            <Content.Section>
                <Content.Section.Header>
                    <Content.Section.Header.Title>Requests by Day Of Week</Content.Section.Header.Title>
                    <Content.Section.Header.Subtitle>All time</Content.Section.Header.Subtitle>
                </Content.Section.Header>
                <Content.Section.Body>
                    <Bar options={barChartOptions} data={data?.by_day_of_week} />
                </Content.Section.Body>
            </Content.Section>

            <Content.Section>
                <Content.Section.Header>
                    <Content.Section.Header.Title>Requests by Day</Content.Section.Header.Title>
                    <Content.Section.Header.Subtitle>Last 30 days</Content.Section.Header.Subtitle>
                </Content.Section.Header>
                <Content.Section.Body>
                    <Line options={lineChartOptions} data={data?.by_day} />
                </Content.Section.Body>
            </Content.Section>

            <Content.Section>
                <Content.Section.Header>
                    <Content.Section.Header.Title>Requests by Month</Content.Section.Header.Title>
                    <Content.Section.Header.Subtitle>All time</Content.Section.Header.Subtitle>
                </Content.Section.Header>
                <Content.Section.Body>
                    <Line options={monthLineChartOptions} data={data?.by_month} />
                </Content.Section.Body>
            </Content.Section>
        </Content>
    )
}
