import { Menu } from '@headlessui/react'
import { classNames } from '../../layouts/AppLayout/AppLayout'
import { ProfileMenuItemType } from './ProfileMenu'
import { NavLink } from 'react-router-dom'

export const profileMenuItemCss = 'block px-4 py-2 text-sm text-gray-700'

export const ProfileMenuItem = ({ item }: { item: ProfileMenuItemType; key: number }) => (
    <Menu.Item>
        {({ active }) => (
            <NavLink to={item.url} className={classNames(active ? 'bg-gray-100' : '', profileMenuItemCss)}>
                {item.name}
            </NavLink>
        )}
    </Menu.Item>
)
