import { API } from '../lib/API'
import { useQuery } from 'react-query'

export const useFuelTrucks = () => {
    const fetchResource = async () => {
        console.log('Fetching fuel trucks')
        const data = await API.get('fuel_trucks', true)
        return await data.json()
    }

    return useQuery(['fuel_trucks'], fetchResource)
}
