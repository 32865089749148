import React from 'react'

export const CommentBox = ({ value, onChange }: { value: string; onChange: Function }) => (
    <div className="flex items-start space-x-4">
        <div className="min-w-0 flex-1">
            <div className="border-b border-gray-200 focus:border-brand-primary-600">
                <textarea
                    rows={3}
                    name="comment"
                    id="comment"
                    className="block w-full bg-gray-50 border-0 border-b border-transparent p-0 pb-2 resize-none focus:ring-0 focus:border-brand-primary-600 sm:text-sm"
                    defaultValue={value}
                    onChange={(event) => onChange(event)}
                />
            </div>
        </div>
    </div>
)
