import { Content } from '../shared/Content'
import { useParams } from 'react-router-dom'
import { useFuelTruck } from '../../hooks/useFuelTruck'
import React, { useState } from 'react'
import { FuelTruckDetails } from './FuelTruckDetails'
import { FuelTruckLog } from './FuelTruckLog'
import { FuelTruckLevel } from './FuelTruckLevel'

export const FuelTruck = () => {
    const { id } = useParams()
    const { data: fuelTruck, isLoading } = useFuelTruck(id)

    const [editing, setEditing] = useState(false)

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <Content>
            <Content.Header>
                <Content.Header.Title>{fuelTruck.data.attributes.name}</Content.Header.Title>
            </Content.Header>

            <Content.Container>
                <Content.LeftColumn>
                    <FuelTruckDetails fuelTruck={fuelTruck} editing={editing} setEditing={setEditing} />
                    <FuelTruckLog />
                </Content.LeftColumn>

                <Content.RightColumn>
                    <Content.ColumnSection>
                        <FuelTruckLevel
                            level={fuelTruck.data.attributes.level}
                            capacity={fuelTruck.data.attributes.capacity}
                        />
                    </Content.ColumnSection>
                </Content.RightColumn>
            </Content.Container>
        </Content>
    )
}
