import { NavLink } from 'react-router-dom'
import { NavigationItemsType } from '../../types/NavigationMenuItems'
import { HOVER_CLASSES, MENU_BASE_CLASSES } from './SideMenuItems'

interface SideMenuItemLinkWrapperProps {
    item: NavigationItemsType
    indent?: boolean
    children: React.ReactNode
}

export const SideMenuItemLinkWrapper = ({ item, indent = false, children }: SideMenuItemLinkWrapperProps) => {
    let linkClasses = MENU_BASE_CLASSES
    linkClasses += indent ? ' px-12' : ' px-2'

    if (item.href) {
        return (
            <NavLink
                to={item.href}
                className={(navData) =>
                    (navData.isActive ? 'bg-brand-primary-700' : '') + ` ${HOVER_CLASSES} ${linkClasses}`
                }
            >
                {children}
            </NavLink>
        )
    }

    return <div className={linkClasses}>{children}</div>
}
