import React from 'react'
import ClockLoader from 'react-spinners/ClockLoader'

interface ButtonLabelWithLoadingIconProps {
    loading: boolean
    children: React.ReactNode
    size?: number
    color?: string
}

export const ButtonLabelWithLoadingIcon = ({
    loading,
    children,
    size = 20,
    color = '#fff',
}: ButtonLabelWithLoadingIconProps) => (
    <>
        <div className="flex-1"></div>
        <div className="flex">{children}</div>
        <div className="flex-1 items-emd">
            <div className="flex justify-end">
                <ClockLoader loading={loading} color={color} size={size} className="ml-1 flex self-end w-full" />
            </div>
        </div>
    </>
)
