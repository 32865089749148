import { API } from '../lib/API'
import { useQuery } from 'react-query'

export const useFuelTruck = (id: string | undefined) => {
    const fetchResource = async () => {
        if (!id) return

        console.log('Fetching fuel truck ' + id)
        const data = await API.get(['fuel_trucks', id], true)
        return await data.json()
    }

    return useQuery(['fuel_trucks', id], fetchResource)
}
