import {
    ChartBarIcon,
    DesktopComputerIcon,
    HomeIcon,
    OfficeBuildingIcon,
    PaperAirplaneIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/outline'
import { NavigationItemsType } from '../../types/NavigationMenuItems'
import { TiPlaneOutline } from 'react-icons/ti'
import { RiGasStationLine } from 'react-icons/ri'

export const HOVER_CLASSES = 'hover:text-white hover:bg-brand-primary-700'
export const MENU_BASE_CLASSES =
    'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 '

export const SideMenuItems: NavigationItemsType[] = [
    {
        name: 'Home',
        icon: HomeIcon,
        href: '/',
    },
    {
        name: 'Requests',
        icon: PaperAirplaneIcon,
        count: 3,
        subMenuItems: [
            { name: "Today's Requests", href: '/requests/today', employeeOnly: true },
            { name: 'All Requests', href: '/requests/all' },
        ],
    },
    {
        name: 'Dashboard',
        icon: DesktopComputerIcon,
        externalLink: true,
        href: '/dashboard',
        employeeOnly: true,
    },
    {
        name: 'Aircraft',
        icon: TiPlaneOutline,
        href: '/aircraft',
    },
    {
        name: 'Reports',
        icon: ChartBarIcon,
        href: '/reports',
        employeeOnly: true,
    },
    {
        name: 'FBOs',
        icon: OfficeBuildingIcon,
        href: '/fbos',
        show: false,
    },
    {
        name: 'Fuel',
        icon: RiGasStationLine,
        employeeOnly: true,
        show: true,
        subMenuItems: [
            { name: 'Fuel Requests', href: '/fuel/requests', employeeOnly: true },
            { name: 'Fuel Trucks', href: '/fuel/trucks', employeeOnly: true },
        ],
    },
]

export const SecondaryMenuItems = [
    {
        name: 'Support',
        href: '/support',
        icon: QuestionMarkCircleIcon,
    },
]
