import React from 'react'

export const ShowCard = ({ children }: { children: React.ReactNode }) => (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">{children}</div>
)

const Title = ({ children }: { children: React.ReactNode }) => (
    <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
        {children}
    </h2>
)

const ContentContainer = ({ children }: { children: React.ReactNode }) => (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">{children}</dl>
)

const Name = ({ children }: { children: React.ReactNode }) => (
    <dt className="text-sm font-medium text-gray-500">{children}</dt>
)

const Value = ({ children }: { children: React.ReactNode }) => (
    <dd className="mt-1 text-sm text-gray-900">{children}</dd>
)

const Item = ({ children }: { children: React.ReactNode }) => <div className="sm:col-span-1">{children}</div>

Item.Name = Name
Item.Value = Value
ShowCard.Item = Item
ShowCard.Title = Title

ShowCard.ContentContainer = ContentContainer
