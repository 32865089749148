import React from 'react'

// @ts-ignore
export const FormInput = ({ label, name, ...props }) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={name} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                    type="text"
                    className="max-w-lg block w-full shadow-sm focus:ring-brand-primary-500 focus:border-brand-primary-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    {...props}
                />
            </div>
        </div>
    )
}
