import { ShowCard } from '../shared/ShowCard'
import React from 'react'
import { NoContent } from '../shared/NoContent'
import { Content } from '../shared/Content'

export const FuelTruckLog = () => {
    return (
        <>
            <Content.Header.Title>Truck Log</Content.Header.Title>
            <Content.ColumnSection>
                <ShowCard>
                    <NoContent>no fuelings recorded</NoContent>
                </ShowCard>
            </Content.ColumnSection>
        </>
    )
}
