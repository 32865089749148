import React from 'react'
import { ReactChildrenProp } from '../../../types/ReactChildrenProp'
import { Header } from './Section/Header'
import { Body } from './Section/Body'

export const Section = ({ children }: ReactChildrenProp) => (
    <div className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 mb-8">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">{children}</div>
            </section>
        </div>
    </div>
)

Section.Header = Header
Section.Body = Body
