import { RequestCardInput } from '../Request/RequestCard/RequestCardInput/RequestCardInput'
import React, { useContext, useEffect, useState } from 'react'
import { PRIMARY_BUTTON_COLOR } from '../../colors'
import { IoPaperPlaneOutline } from 'react-icons/io5'
import { API } from '../../lib/API'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { Errors } from '../shared/Errors'
import { AuthContext } from '../../contexts/AuthContext'
import { toast } from '../../lib/toast'
import { ButtonLabelWithReplacementLoadingIcon } from '../shared/ButtonLabelWithReplacementLoadingIcon'

export const NewRequest = () => {
    const [aircraft, setAircraft] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [comments, setComments] = useState('')
    const [stageTime, setStageTime] = useState('')
    const [departureTime, setDepartureTime] = useState('')
    const [arrivalTime, setArrivalTime] = useState('')
    const [errors, setErrors] = useState([])

    const updatePhone = (event: { target: { value: any } }) => setPhoneNumber(event.target.value)
    const updateComments = (event: { target: { value: any } }) => setComments(event.target.value)
    const updateStageTime = (event: any) => setStageTime(event)
    const updateDepartureTime = (event: any) => setDepartureTime(event)
    const updateArrivalTime = (event: any) => setArrivalTime(event)
    const updateAircraft = (value: any) => {
        value.target ? setAircraft(value.target.value) : setAircraft(value)
    }
    const navigate = useNavigate()
    const auth = useContext(AuthContext)

    useEffect(() => {
        if (!auth.user) {
            return
        }
        setPhoneNumber(auth?.user?.data?.attributes?.phone_number)
    }, [auth.user, setPhoneNumber])

    const queryClient = useQueryClient()
    const mutation = useMutation(async () => {
        const response = await API.post(['requests'], {
            aircraft: aircraft,
            phone_number: phoneNumber,
            comments: comments,
            stage_time: stageTime,
            departure_time: departureTime,
            arrival_time: arrivalTime,
            customer_id: auth.user ? auth?.user?.data?.id : null,
        })

        const responseJson = await response.json()

        if (API.successfulResponse(response.status)) {
            queryClient.invalidateQueries('requests')

            if (auth.authenticated) {
                navigate('/requests/' + responseJson.id)
                toast.success('Your request has been created')
                return
            } else {
                navigate('/requests/new/submitted')
                return
            }
        }
        setErrors(responseJson.errors)
    })

    const submitForm = async () => {
        mutation.mutate()
    }

    return (
        <section aria-labelledby="new-request-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="flex">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="new-request-title" className="text-lg leading-6 font-medium text-gray-900">
                            New Service Request
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Submit a new service request to Mid Island Air Service.
                        </p>
                    </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <Errors errors={errors} />
                    <RequestCardInput
                        aircraft={aircraft}
                        updateAircraft={updateAircraft}
                        phoneNumber={phoneNumber}
                        updatePhoneNumber={updatePhone}
                        comments={comments}
                        updateComments={updateComments}
                        stageTime={stageTime}
                        updateStageTime={updateStageTime}
                        departureTime={departureTime}
                        updateDepartureTime={updateDepartureTime}
                        arrivalTime={arrivalTime}
                        updateArrivalTime={updateArrivalTime}
                    />
                </div>
                <div className="flex justify-end">
                    <div className="px-4 py-5 sm:px-6">
                        <button
                            type="button"
                            className={`inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${PRIMARY_BUTTON_COLOR}`}
                            onClick={submitForm}
                            disabled={mutation.isLoading}
                        >
                            <ButtonLabelWithReplacementLoadingIcon
                                loading={mutation.isLoading}
                                icon={IoPaperPlaneOutline}
                                iconClass="flex ml-2 mr-1 h-5 w-5"
                            >
                                Submit
                            </ButtonLabelWithReplacementLoadingIcon>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
