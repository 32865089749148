import { NewRequest } from '../NewRequest/NewRequest'
import React from 'react'
import { Metar } from './Metar'

export const UserHome = () => {
    return (
        <>
            <Metar />
            {/*<div className="space-y-6 lg:col-start-1 lg:col-span-2 pb-5">*/}
            {/*    <div className="bg-white shadow sm:rounded-lg align-middle">*/}
            {/*        <Content.Section.Header>*/}
            {/*            <Content.Section.Header.Title>Quick Actions</Content.Section.Header.Title>*/}
            {/*        </Content.Section.Header>*/}
            {/*        <Content.Section.Body>*/}
            {/*            <PrimaryButton>Pull my plane now</PrimaryButton>*/}
            {/*            <span className="pl-4" />*/}
            {/*            <PrimaryButton>Pull my plane in an hour</PrimaryButton>*/}
            {/*        </Content.Section.Body>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <NewRequest />
        </>
    )
}
