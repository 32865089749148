export const Card = ({ children }: { children: React.ReactNode }) => (
    <div className="hidden sm:block">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col mt-2">
                <div className="align-middle bg-white min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                    {children}
                </div>
            </div>
        </div>
    </div>
)
