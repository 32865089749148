import { MenuItem } from './MenuItem'
import { ThumbUpIcon } from '@heroicons/react/outline'
import React from 'react'

export interface ActionProps {
    status: string
    id: string
    mutation: any
}

export const Acknowledge = ({ status, id, mutation }: ActionProps) => {
    if (status === 'new') {
        return (
            <MenuItem onClick={() => mutation.mutate({ action: 'acknowledge', id: id })}>
                <ThumbUpIcon className="mr-3 h-5 w-5 text-green-500 group-hover:text-green-600" aria-hidden="true" />
                Acknowledge
            </MenuItem>
        )
    }

    return null
}
