import { Switch } from '@headlessui/react'
import { CogIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { Content } from '../shared/Content'
import { classNames } from '../../layouts/AppLayout/AppLayout'
import { useProfile } from '../../hooks/useProfile'
import { useMutation, useQueryClient } from 'react-query'
import { API } from '../../lib/API'
import { toast } from '../../lib/toast'

export const Settings = () => {
    const { data: user, isLoading } = useProfile()
    const [emailNotifications, setEmailNotifications] = useState(false)
    const [smsNotifications, setSmsNotifications] = useState(false)

    const queryClient = useQueryClient()
    const mutation = useMutation(
        (data: any) => {
            return API.patch(['users', 'profile'], { user: data })
        },
        {
            onSuccess: async (data) => {
                if (API.successfulResponse(data.status)) {
                    console.debug('invalidating profile')
                    toast.success('Your settings have been updated')
                    await queryClient.invalidateQueries(['users', 'profile'])
                    return
                }
            },
        }
    )

    useEffect(() => {
        setEmailNotifications(user?.data.attributes.email_notifications)
        setSmsNotifications(user?.data.attributes.sms_notifications)
    }, [user])

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <Content>
            <Content.Header>
                <Content.Header.Title>
                    <CogIcon className="h-8 mr-2 w-auto" /> Settings
                </Content.Header.Title>
            </Content.Header>

            <Content.Section>
                <Content.Section.Header>
                    <Content.Section.Header.Title>Notifications</Content.Section.Header.Title>
                </Content.Section.Header>
                <Content.Section.Body>
                    <Switch.Group as="div" className="flex items-center justify-between">
                        <span className="flex-grow flex flex-col">
                            <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                Email Notifications
                            </Switch.Label>
                        </span>
                        <Switch
                            checked={emailNotifications}
                            onChange={() => mutation.mutate({ email_notifications: !emailNotifications })}
                            className={classNames(
                                emailNotifications ? 'bg-brand-primary-600' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary-500'
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    emailNotifications ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                )}
                            />
                        </Switch>
                    </Switch.Group>

                    <Switch.Group as="div" className="flex items-center justify-between mt-3">
                        <span className="flex-grow flex flex-col">
                            <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                Enable SMS Notifications
                            </Switch.Label>
                        </span>
                        <Switch
                            checked={smsNotifications}
                            onChange={() => mutation.mutate({ sms_notifications: !smsNotifications })}
                            className={classNames(
                                smsNotifications ? 'bg-brand-primary-600' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary-500'
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    smsNotifications ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                )}
                            />
                        </Switch>
                    </Switch.Group>
                </Content.Section.Body>
            </Content.Section>
        </Content>
    )
}
