import React, { useState } from 'react'
import { FormInput } from './FormInput'
import { useMutation, useQueryClient } from 'react-query'
import { API } from '../../lib/API'
import { PRIMARY_BUTTON_COLOR } from '../../colors'
import { toast } from '../../lib/toast'
import { Errors } from '../shared/Errors'
import { ButtonLabelWithLoadingIcon } from '../shared/ButtonLabelWithLoadingIcon'

interface ProfileFormProps {
    email: string
    updateEmail: Function
    firstName: string
    updateFirstName: Function
    lastName: string
    updateLastName: Function
    phoneNumber: string
    updatePhoneNumber: Function
}

// @ts-ignore
export const ProfileForm = ({
    email,
    updateEmail,
    firstName,
    updateFirstName,
    lastName,
    updateLastName,
    phoneNumber,
    updatePhoneNumber,
}: ProfileFormProps) => {
    const [errors, setErrors] = useState([])
    const queryClient = useQueryClient()
    const mutation = useMutation(
        () => {
            return API.updateProfile({
                email: email,
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
            })
        },
        {
            onSuccess: async (data) => {
                if (data.status >= 200 || data.status <= 300) {
                    console.debug('invalidating profile')
                    toast.success('Your profile has been updated')
                    queryClient.invalidateQueries(['users', 'profile'])
                    return
                }

                const responseJson = await data.json()
                setErrors(responseJson?.errors)
            },
        }
    )

    const onSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        mutation.mutate()
    }

    return (
        <form onSubmit={onSubmit} className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="space-y-6 sm:space-y-5">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Your phone number may receive text messages about the status of your requests.
                        </p>
                    </div>
                    <Errors errors={errors} />
                    <div className="space-y-6 sm:space-y-5">
                        <FormInput value={email} onChange={updateEmail} label="Email" name="email" />
                        <FormInput value={firstName} onChange={updateFirstName} label="First Name" name="first_name" />
                        <FormInput value={lastName} onChange={updateLastName} label="Last Name" name="last_name" />
                        <FormInput
                            value={phoneNumber}
                            onChange={updatePhoneNumber}
                            label="Phone Number"
                            name="phone_number"
                        />
                    </div>
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">
                        <div>
                            <button
                                type="submit"
                                disabled={mutation.isLoading}
                                className={
                                    'w-full flex flex-grow-2 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium ' +
                                    PRIMARY_BUTTON_COLOR
                                }
                            >
                                <div className="flex w-20">
                                    <ButtonLabelWithLoadingIcon loading={mutation.isLoading}>
                                        Save
                                    </ButtonLabelWithLoadingIcon>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
