import { InputLabel } from './InputLabel'
import React from 'react'

export const InputItem = ({
    label,
    children,
    span = 1,
}: {
    label: string
    children: React.ReactNode
    span?: number
}) => (
    <div className={'sm:col-span-' + span}>
        <InputLabel>{label}</InputLabel>
        {children}
    </div>
)
