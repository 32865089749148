import { API } from '../../lib/API'
import { useQuery } from 'react-query'
import { Content } from '../shared/Content'
import React from 'react'
import { PulseLoader } from 'react-spinners'

export const Metar = () => {
    const fetchMetar = async () => {
        const data = await API.metar()
        return await data.json()
    }

    const { data, error, isError, isLoading } = useQuery(['metar'], fetchMetar)

    if (isLoading) {
        return (
            <div className="space-y-6 lg:col-start-1 lg:col-span-2 pb-5">
                <div className="bg-white shadow sm:rounded-lg align-middle">
                    <Content.Section.Body>
                        <PulseLoader size={8} color="#1472A6" />
                    </Content.Section.Body>
                </div>
            </div>
        )
    }

    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }

    return (
        <div className="space-y-6 lg:col-start-1 lg:col-span-2 pb-5">
            <div className="bg-white shadow sm:rounded-lg">
                <Content.Section.Body>
                    <p className="text-sm font-mono">{data}</p>
                </Content.Section.Body>
            </div>
        </div>
    )
}
