import { Combobox, Transition } from '@headlessui/react'
import { IAircraft } from '../../../../../types/IAircraft'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import React, { Fragment, useState } from 'react'

interface AircraftComboboxInputProps {
    aircraft: string
    updateAircraft: Function
    availableAircraft: IAircraft[]
}

export const AircraftComboboxInput = ({ aircraft, updateAircraft, availableAircraft }: AircraftComboboxInputProps) => {
    const [selectedAircraft, setSelectedAircraft] = useState({
        data: { attributes: { tail_number: aircraft } },
    })
    const [query, setQuery] = useState('')

    const updateSelectedAircraft = (item: any) => {
        setSelectedAircraft(item)
        updateAircraft(item.data.attributes.tail_number)
    }

    const filteredAircraft =
        query === ''
            ? availableAircraft
            : availableAircraft.filter((aircraft: IAircraft) => {
                  return aircraft.data.attributes.tail_number.toLowerCase().includes(query.toLowerCase())
              })

    return (
        <div className="mt-1 border-b border-gray-300 focus:border-brand-primary-600">
            <Combobox value={selectedAircraft} onChange={updateSelectedAircraft}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden bg-gray-50 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-brand-primary-600 focus:ring-0 sm:text-sm"
                            onChange={(event) => setQuery(event.target.value)}
                            displayValue={(aircraft: IAircraft) => aircraft?.data?.attributes?.tail_number}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto bg-gray-50 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                            {query.length > 0 && (
                                <Combobox.Option
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-brand-primary-600 text-white' : 'text-gray-900'
                                        }`
                                    }
                                    value={{
                                        data: {
                                            attributes: { tail_number: query },
                                        },
                                    }}
                                >
                                    <ComboboxOptionLabel value={`Use ${query}`} />
                                </Combobox.Option>
                            )}
                            {filteredAircraft.map((aircraft: IAircraft) => (
                                <Combobox.Option
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-brand-primary-600 text-white' : 'text-gray-900'
                                        }`
                                    }
                                    key={aircraft.data.id}
                                    value={aircraft}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <ComboboxOptionLabel value={aircraft.data.attributes.tail_number} />
                                            <SelectedCheckmark selected={selected} active={active} />
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}

const ComboboxOptionLabel = ({ value }: { value: string }) => <span className="sm:text-sm">{value}</span>

const SelectedCheckmark = ({ selected, active }: { selected: boolean; active: boolean }) =>
    selected ? (
        <span
            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                active ? 'text-white' : 'text-brand-secondary-600'
            }`}
        >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
    ) : null
