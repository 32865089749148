import { ReactChildrenProp } from '../../../../types/ReactChildrenProp'
import React from 'react'
import { Title } from './Header/Title'
import { Subtitle } from './Header/Subtitle'

export const Header = ({ children }: ReactChildrenProp) => (
    <div className="divide-y divide-gray-200">
        <div className="px-4 py-3 sm:px-6">{children}</div>
    </div>
)

Header.Title = Title
Header.Subtitle = Subtitle
