import { MenuItem } from './MenuItem'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import React from 'react'

interface DashboardVisibilityProps {
    showOnDashboard: boolean
    id: string
    mutation: any
}

export const DashboardVisibility = ({ showOnDashboard, id, mutation }: DashboardVisibilityProps) => {
    if (showOnDashboard) {
        return (
            <MenuItem onClick={() => mutation.mutate({ action: 'hideOnDashboard', id: id })}>
                <EyeOffIcon
                    className="mr-3 h-5 w-5 text-brand-primary-400 group-hover:text-brand-primary-500"
                    aria-hidden="true"
                />
                Hide on dashboard
            </MenuItem>
        )
    }

    if (!showOnDashboard) {
        return (
            <MenuItem onClick={() => mutation.mutate({ action: 'showOnDashboard', id: id })}>
                <EyeIcon
                    className="mr-3 h-5 w-5 text-brand-primary-400 group-hover:text-brand-primary-500"
                    aria-hidden="true"
                />
                Show on dashboard
            </MenuItem>
        )
    }

    return null
}
