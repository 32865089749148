import { MenuItem } from './MenuItem'
import { XCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import { ActionProps } from './Acknowledge'

export const Reject = ({ status, id, mutation }: ActionProps) => {
    if (status === 'new' || status === 'acknowledged') {
        return (
            <MenuItem onClick={() => mutation.mutate({ action: 'reject', id: id })}>
                <XCircleIcon className="mr-3 h-5 w-5 text-red-400 group-hover:text-gray-500" aria-hidden="true" />
                Reject
            </MenuItem>
        )
    }

    return null
}
