import { BUTTON_BASE_CLASSES } from './Button'
import { PencilAltIcon } from '@heroicons/react/solid'
import React from 'react'

export const EditButton = ({ setEditing }: { setEditing: Function }) => (
    <button
        type="button"
        className={`${BUTTON_BASE_CLASSES} self-start bg-white text-grey-700 hover:bg-gray-50`}
        onClick={() => setEditing(true)}
    >
        <PencilAltIcon className="mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        Edit
    </button>
)
