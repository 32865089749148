import { ShowCard } from '../shared/ShowCard'
import React from 'react'
import { capacityPerCent } from './FuelTrucks'

interface FuelTruckLevelProps {
    level: number
    capacity: number
}

export const FuelTruckLevel = ({ level, capacity }: FuelTruckLevelProps) => {
    return (
        <>
            <div className="flex align-middle items-end bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="request-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Level
                </h2>
                <div className="flex text-xs text-gray-500 ml-1">
                    {level} / {capacity}
                </div>
                <div className="flex text-xs text-gray-500 ml-1">({capacityPerCent(level, capacity)}%)</div>
            </div>
            <ShowCard>
                <div className="flex justify-center flex-grow mr-2">
                    <div className="flex border-t flex-col-reverse rounded mt-4 h-48 w-20 bg-brand-primary-200">
                        <div
                            style={{
                                flexBasis: `${capacityPerCent(level, capacity)}%`,
                            }}
                            className="bg-brand-primary-500"
                        ></div>
                    </div>
                </div>
            </ShowCard>
        </>
    )
}
