import { RequestRow } from './RequestRow/RequestRow'
import { RequestType } from '../../types/RequestType'

export const DashboardPane = ({ requests, label = undefined }: { requests: RequestType[]; label?: string }) => (
    <>
        {label ? (
            <div className="mt-8 mb-2 ml-2 text-center">
                <h1 className="text-2xl font-semibold ">{label}</h1>
            </div>
        ) : null}

        {requests.length === 0 ? (
            <div className="w-full flex justify-center">
                <div className="italic text-sm text-gray-500">(no requests)</div>
            </div>
        ) : (
            <div className="bg-white shadow sm:rounded-md">
                <ul className="divide-y-8 divide-gray-200">
                    {requests.map((request, i) => (
                        <RequestRow key={i} request={request} />
                    ))}
                </ul>
            </div>
        )}
    </>
)
