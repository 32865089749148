import React, { useState } from 'react'
import { PRIMARY_BUTTON_COLOR } from '../../colors'
import { IoPaperPlaneOutline } from 'react-icons/io5'
import { API } from '../../lib/API'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { PhxErrors } from '../shared/Errors'
import { toast } from '../../lib/toast'
import { ButtonLabelWithReplacementLoadingIcon } from '../shared/ButtonLabelWithReplacementLoadingIcon'
import { FuelTruckInput } from './FuelTruckInput'
import { ShowCard } from '../shared/ShowCard'
import { Content } from '../shared/Content'

export const NewFuelTruck = () => {
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [meter, setMeter] = useState(0)
    const [level, setLevel] = useState(0)
    const [capacity, setCapacity] = useState(0)
    const [errors, setErrors] = useState({})

    const updateName = (event: { target: { value: any } }) => setName(event.target.value)
    const updateType = (event: { target: { value: any } }) => setType(event.target.value)
    const updateMeter = (event: { target: { value: any } }) => setMeter(event.target.value)
    const updateLevel = (event: { target: { value: any } }) => setLevel(event.target.value)
    const updateCapacity = (event: { target: { value: any } }) => setCapacity(event.target.value)

    const queryClient = useQueryClient()
    const mutation = useMutation(
        () => {
            return API.post(
                ['fuel_trucks'],
                {
                    fuel_truck: {
                        name: name,
                        type: type,
                        meter: meter,
                        level: level,
                        capacity: capacity,
                    },
                },
                true
            )
        },
        {
            onSuccess: async (data) => {
                const responseJson = await data.json()

                if (API.successfulResponse(data.status)) {
                    toast.success('Your truck has been created')
                    navigate('/fuel/trucks/' + responseJson.data.id)
                    console.debug('invalidating fuel trucks')
                    await queryClient.invalidateQueries(['fuel_trucks'])
                    setErrors([])
                    return
                }

                setErrors(responseJson?.errors)
            },
        }
    )

    return (
        <Content>
            <PhxErrors errors={errors} />
            <Content.Header>
                <Content.Header.Title>New Fuel Truck</Content.Header.Title>
            </Content.Header>
            <Content.Section>
                <ShowCard>
                    <PhxErrors errors={errors} />
                    <ShowCard.ContentContainer>
                        <FuelTruckInput
                            name={name}
                            updateName={updateName}
                            type={type}
                            updateType={updateType}
                            meter={meter}
                            updateMeter={updateMeter}
                            level={level}
                            updateLevel={updateLevel}
                            capacity={capacity}
                            updateCapacity={updateCapacity}
                        />
                    </ShowCard.ContentContainer>
                </ShowCard>
                <div className="flex justify-end">
                    <div className="px-4 py-5 sm:px-6">
                        <button
                            type="button"
                            className={`inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${PRIMARY_BUTTON_COLOR}`}
                            onClick={() => mutation.mutate()}
                            disabled={mutation.isLoading}
                        >
                            <ButtonLabelWithReplacementLoadingIcon
                                loading={mutation.isLoading}
                                icon={IoPaperPlaneOutline}
                                iconClass="flex ml-2 mr-1 h-5 w-5"
                            >
                                Create
                            </ButtonLabelWithReplacementLoadingIcon>
                        </button>
                    </div>
                </div>
            </Content.Section>
        </Content>
    )
}
