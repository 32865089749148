import { API } from '../lib/API'
import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import { useCookies } from 'react-cookie'
import { Logo } from './shared/Logo'
import { PRIMARY_BUTTON_COLOR } from '../colors'
import { LockClosedIcon, MailIcon } from '@heroicons/react/outline'
import { ButtonLabelWithLoadingIcon } from './shared/ButtonLabelWithLoadingIcon'

export const SignIn = () => {
    const navigate = useNavigate()
    const auth = useContext(AuthContext)

    const [, setCookie, removeCookie] = useCookies()

    const [email, setEmail] = useState('test@example.com')
    const [password, setPassword] = useState('password')
    const [submitting, setSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const emailInputOnChange = (event: any) => setEmail(event.target.value)
    const passwordInputOnChange = (event: any) => setPassword(event.target.value)

    const submitForm = async (event: any) => {
        setSubmitting(true)
        setErrorMessage('')
        event.preventDefault()

        console.log('Submitting')

        const response = await API.signIn({ email: email, password: password })
        const responseJson = await response.json()

        setSubmitting(false)
        if (response.status !== 201) {
            // TODO Handle error
            setErrorMessage(responseJson.error)
            return
        }

        const authHeader = await response.headers.get('Authorization')
        console.log('Auth header is ' + authHeader)

        if (authHeader) {
            const token = authHeader.split(' ')[1]

            auth.setUser(responseJson)
            auth.setAuthenticated(true)

            removeCookie('token')
            setCookie('token', token, {
                path: '/',
                sameSite: 'lax',
                domain: window.location.hostname,
            })
            navigate('/')
        }
    }

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <Logo className="mx-auto h-16 w-auto" />
                <h2 className="mt-1 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="h-8 text-red-700 text-center">{errorMessage}</div>
                    <form className="space-y-6" onSubmit={(event) => submitForm(event)}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none pl-10 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-primary-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={emailInputOnChange}
                                    placeholder="you@example.com"
                                />
                            </div>
                            <div className="mt-1"></div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    placeholder="password"
                                    onChange={passwordInputOnChange}
                                    className="appearance-none pl-10 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-primary-500 focus:border-brand-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 text-brand-primary-600 focus:ring-brand-primary-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm">
                                <Link
                                    to="/forgot_password"
                                    className="font-medium text-brand-primary-600 hover:text-brand-primary-500"
                                >
                                    Forgot your password?
                                </Link>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={submitting}
                                className={
                                    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium ' +
                                    PRIMARY_BUTTON_COLOR
                                }
                            >
                                <div className="flex w-full">
                                    <ButtonLabelWithLoadingIcon loading={submitting}>
                                        Sign In
                                    </ButtonLabelWithLoadingIcon>
                                </div>
                            </button>
                        </div>
                    </form>

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">Or</span>
                            </div>
                        </div>

                        <Link to="/sign_up" className="font-medium text-brand-primary-600 hover:text-brand-primary-500">
                            <div className="mt-6 text-center ">Sign up</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
