import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Timeline } from './Timeline'
import { Breadcrumbs } from './Breadcrumbs'
import { DATE_FORMAT, Time, TIME_FORMAT } from '../shared/Time'
import { RequestCard } from './RequestCard/RequestCard'
import { PRIMARY_BUTTON_COLOR } from '../../colors'
import { BUTTON_BASE_CLASSES } from '../shared/Button'
import { RequestActions } from './RequestCard/RequestActions/RequestActions'
import { API } from '../../lib/API'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Notes } from './Notes/Notes'
import { useQuery } from 'react-query'
import { FuelRequest } from './FuelRequest'
import { EmployeeOnly } from '../shared/EmployeeOnly'

export const Request = () => {
    const params = useParams()

    const [editing, setEditing] = useState(false)

    const fetchRequest = async () => {
        console.log('fetching request ' + params.id)
        if (!params.id) {
            return
        }
        const data = await API.request(params.id)
        return await data.json()
    }

    const { data, error, isError, isLoading } = useQuery(['request', params.id], fetchRequest)

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }
    if (data.error || !data.data) {
        return <div>Error! {data.error}</div>
    }
    return (
        <>
            <div className="mb-1 max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <Breadcrumbs />
            </div>
            <main>
                {/* Page header */}
                <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {data.data.attributes.aircraft} Service Request
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                Complete by{' '}
                                <span className="text-gray-900">
                                    <Time format={DATE_FORMAT}>{data.data.attributes.earliest_time}</Time>@
                                    <Time format={TIME_FORMAT}>{data.data.attributes.earliest_time}</Time>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <RequestActions request={data}>
                            <Menu.Button className={`${BUTTON_BASE_CLASSES} ${PRIMARY_BUTTON_COLOR}`}>
                                Actions <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                        </RequestActions>
                    </div>
                </div>

                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                        <RequestCard request={data} editing={editing} setEditing={setEditing} />
                        <EmployeeOnly>
                            <FuelRequest />
                        </EmployeeOnly>
                        <Notes />
                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                        <Timeline request={data} />
                    </section>
                </div>
            </main>
        </>
    )
}
