import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { TextInput } from '../../../shared/TextInput'
import { CommentBox } from './CommentBox'
import { DateTimeInput } from './DateTimeInput'
import { AircraftInput } from './AircraftInput/AircraftInput'
import { InputItem } from './InputItem'

interface RequestCardInputProps {
    aircraft: string
    updateAircraft: Function
    phoneNumber: string
    updatePhoneNumber: Function
    comments: string
    updateComments: Function
    stageTime: string
    updateStageTime: Function
    departureTime: string
    updateDepartureTime: Function
    arrivalTime: string
    updateArrivalTime: Function
}
export const RequestCardInput = ({
    aircraft,
    updateAircraft,
    phoneNumber,
    updatePhoneNumber,
    comments,
    updateComments,
    stageTime,
    updateStageTime,
    departureTime,
    updateDepartureTime,
    arrivalTime,
    updateArrivalTime,
}: RequestCardInputProps) => {
    return (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <InputItem label="Aircraft">
                <AircraftInput updateAircraft={updateAircraft} aircraft={aircraft} />
            </InputItem>
            <InputItem label="Stage Time">
                <DateTimeInput value={stageTime} updateValue={updateStageTime} />
            </InputItem>
            <InputItem label="Departure Time">
                <DateTimeInput value={departureTime} updateValue={updateDepartureTime} />
            </InputItem>
            <InputItem label="Arrival Time">
                <DateTimeInput value={arrivalTime} updateValue={updateArrivalTime} />
            </InputItem>
            <InputItem label="Phone Number">
                <TextInput id="phone_number" value={phoneNumber} onChange={updatePhoneNumber} />
            </InputItem>
            <InputItem label="Comments" span={2}>
                <CommentBox value={comments} onChange={updateComments} />
            </InputItem>
        </dl>
    )
}
