import React, { useState } from 'react'
import { useFuelTrucks } from '../../hooks/useFuelTrucks'
import { InputItem } from './RequestCard/RequestCardInput/InputItem'
import { FuelTruckData } from '../../types/FuelTruck'
import { TextInput } from '../shared/TextInput'
import { Content } from '../shared/Content'

export const FuelRequest = () => {
    const { data: fuelTrucks, isError, isLoading } = useFuelTrucks()

    const [requestedFuel, setRequestedFuel] = useState()
    const [fuelTruck, setFuelTruck] = useState()
    const [meter, setMeter] = useState<number>()
    const [fuelDelivered, setFuelDelivered] = useState<string>()

    const updateFuelTruck = (event: { target: { value: any } }) =>
        setFuelTruck(fuelTrucks.data.find((fuelTruck: FuelTruckData) => fuelTruck.id === event.target.value))

    const updateRequestedFuel = (event: { target: { value: any } }) => setRequestedFuel(event.target.value)

    const updateMeter = (event: { target: { value: any } }) => {
        if (!fuelTruck) return

        if (event.target.value === '') {
            setMeter(undefined)
            return
        }

        const newMeter = event.target.value
        const oldMeter = fuelTruck['attributes']['meter']

        setMeter(newMeter)

        if (fuelTruck && newMeter > oldMeter) {
            const fuelDelivered = parseFloat(newMeter) - parseFloat(oldMeter)
            setFuelDelivered(fuelDelivered.toFixed(2))
        }
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>Error</div>
    }

    const StaticValue = ({ children }: { children?: React.ReactNode }) => (
        <div className="mt-1 border-b border-gray-300 focus:border-brand-primary-600">
            <div
                className={
                    'block w-full border-0 border-b border-transparent bg-gray-50 focus:border-brand-primary-600 focus:ring-0 sm:text-sm h-full'
                }
            >
                <div className="p-2 pl-3">{children ? children : '\u00A0'}</div>
            </div>
        </div>
    )

    return (
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <div className="bg-white shadow sm:rounded-lg">
                <Content.Section.Header>
                    <Content.Section.Header.Title>Fuel Request</Content.Section.Header.Title>
                </Content.Section.Header>
                <Content.Section.Body>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <InputItem label="Truck" span={2}>
                            <select
                                id="type"
                                name="type"
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-gray-50 border-l-0 border-t-0 border-r-0 border-b-gray-300 focus:outline-none focus:ring-brand-primary-500 focus:border-brand-primary-500 sm:text-sm"
                                onChange={(event) => updateFuelTruck(event)}
                            >
                                <option></option>
                                {fuelTrucks.data.map((fuelTruck: FuelTruckData) => (
                                    <option value={fuelTruck.id}>{fuelTruck?.attributes.name}</option>
                                ))}
                            </select>
                        </InputItem>
                        <InputItem label="Requested Fuel">
                            <TextInput id="requested_fuel" value={requestedFuel} onChange={updateRequestedFuel} />
                        </InputItem>

                        <InputItem label="End Meter">
                            <TextInput id="meter" value={meter?.toString()} onChange={updateMeter} />
                        </InputItem>

                        <InputItem label="Fuel Delivered">
                            <StaticValue>{fuelDelivered}</StaticValue>
                        </InputItem>

                        <InputItem label="Start Meter">
                            <StaticValue>{fuelTruck ? fuelTruck['attributes']['meter'] : null}</StaticValue>
                        </InputItem>
                    </dl>
                </Content.Section.Body>
            </div>
        </div>
    )
}
