import { API } from '../lib/API'
import { useState } from 'react'
import { Logo } from './shared/Logo'
import { PRIMARY_BUTTON_COLOR } from '../colors'
import { LockClosedIcon } from '@heroicons/react/outline'
import { ButtonLabelWithLoadingIcon } from './shared/ButtonLabelWithLoadingIcon'
import { useSearchParams } from 'react-router-dom'

export const ResetPassword = () => {
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])
    const [params] = useSearchParams()

    const passwordInputOnChange = (event: any) => setPassword(event.target.value)
    const passwordInputConfirmationOnChange = (event: any) => setPasswordConfirmation(event.target.value)

    const submitForm = async (event: any) => {
        setSubmitting(true)
        setSuccess(false)
        event.preventDefault()

        const resetPasswordToken = params.get('reset_password_token')

        if (!resetPasswordToken) {
            const error = { Token: ['cannot be blank'] }
            // @ts-ignore
            setErrorMessages(error)
            setSubmitting(false)
            return
        }

        const response = await API.changePassword(password, passwordConfirmation, params.get('reset_password_token'))

        if (response.status <= 200 || response.status >= 299) {
            const responseJson = await response.json()
            setErrorMessages(responseJson.errors)
            setSubmitting(false)
            return
        }

        setErrorMessages([])
        setSubmitting(false)
        setSuccess(true)
    }

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <Logo className="mx-auto h-16 w-auto" />
                <h2 className="mt-1 text-center text-3xl font-extrabold text-gray-900">Change your password</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="h-min-8 mb-4 text-red-700 text-center">
                        {Object.keys(errorMessages).map((error, i) => {
                            // @ts-ignore
                            // TODO Clean this up
                            return <li key={i}>{`${error} ${errorMessages[error]}`}</li>
                        })}
                    </div>
                    <div className="h-8 text-green-600 text-center">
                        {success ? 'Your password has been reset' : null}
                    </div>
                    <form className="space-y-6" onSubmit={(event) => submitForm(event)}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                New Password
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="password"
                                    required
                                    className="appearance-none pl-10 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-primary-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={passwordInputOnChange}
                                />
                            </div>
                            <div className="mt-1"></div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Confirm New Password
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    type="password"
                                    autoComplete="password_confirmation"
                                    required
                                    className="appearance-none pl-10 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-primary-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={passwordInputConfirmationOnChange}
                                />
                            </div>
                            <div className="mt-1"></div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={submitting}
                                className={
                                    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium ' +
                                    PRIMARY_BUTTON_COLOR
                                }
                            >
                                <div className="flex w-full">
                                    <ButtonLabelWithLoadingIcon loading={submitting}>
                                        Reset password
                                    </ButtonLabelWithLoadingIcon>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
