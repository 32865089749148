import React from 'react'
import { Title } from './Title'
import { Subtitle } from './Subtitle'

export const Header = ({ children }: { children: React.ReactNode }) => (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex items-center space-x-5">
            <div>{children}</div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3"></div>
    </div>
)

Header.Title = Title
Header.Subtitle = Subtitle
