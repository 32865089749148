import { OfficeBuildingIcon } from '@heroicons/react/solid'
import { FBORequestAvatar } from '../shared/Avatar'

const ICON_SIZE = '65'

export const LowerBar = ({ firstName, lastName }: { firstName: string; lastName: string }) => (
    <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
        <div className="flex-1 min-w-0">
            {/* Profile */}
            <div className="flex items-center">
                <div className="hidden sm:block">
                    <FBORequestAvatar size={ICON_SIZE} />
                </div>
                <div>
                    <div className="flex items-center">
                        <div className="sm:hidden">
                            <FBORequestAvatar size={ICON_SIZE} />
                        </div>
                        <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            Good morning, {firstName}
                        </h1>
                    </div>
                    <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                        <dt className="sr-only">Company</dt>
                        <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                            <OfficeBuildingIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            Mid Island Air Service
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
)
