import React, { useState } from 'react'
import { RequestCardShow } from './RequestCardShow'
import { RequestCardInput } from './RequestCardInput/RequestCardInput'
import { API } from '../../../lib/API'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { RequestType } from '../../../types/RequestType'
import { Errors } from '../../shared/Errors'
import { toast } from '../../../lib/toast'
import { EditButton } from '../../shared/EditButton'
import { CancelButton } from '../../shared/CancelButton'
import { SaveButton } from '../../shared/SaveButton'

interface RequestCardProps {
    request: RequestType
    editing: boolean
    setEditing: Function
}

export const RequestCard = ({ request, editing, setEditing }: RequestCardProps) => {
    const params = useParams()

    const [aircraft, setAircraft] = useState(request.data.attributes.aircraft)
    const [phoneNumber, setPhoneNumber] = useState(request.data.attributes.phone_number)
    const [comments, setComments] = useState(request.data.attributes.comments)
    const [stageTime, setStageTime] = useState(request.data.attributes.stage_time)
    const [departureTime, setDepartureTime] = useState(request.data.attributes.departure_time)
    const [arrivalTime, setArrivalTime] = useState(request.data.attributes.arrival_time)
    const [errors, setErrors] = useState([])

    // TODO DRY this up
    const updatePhone = (event: { target: { value: any } }) => setPhoneNumber(event.target.value)
    const updateComments = (event: { target: { value: any } }) => setComments(event.target.value)
    const updateStageTime = (event: any) => setStageTime(event)
    const updateDepartureTime = (event: any) => setDepartureTime(event)
    const updateArrivalTime = (event: any) => setArrivalTime(event)
    const updateAircraft = (value: string) => setAircraft(value)

    const queryClient = useQueryClient()
    const mutation = useMutation(
        () => {
            if (!params.id) throw new Error('no params id')
            setErrors([])

            return API.updateRequest({
                id: params.id,
                aircraft: aircraft,
                phone_number: phoneNumber,
                stage_time: stageTime,
                departure_time: departureTime,
                arrival_time: arrivalTime,
                comments: comments,
            })
        },
        {
            onSuccess: async (data) => {
                if (data.status === 201) {
                    setEditing(false)
                    toast.success('Request has been updated')
                    console.debug('invalidating requests')
                    await queryClient.invalidateQueries(['request', params.id])
                    return
                }
                const responseJson = await data.json()
                setErrors(responseJson?.errors)
            },
        }
    )

    const cancelHandler = () => {
        resetForm()
        setEditing(false)
    }

    const resetForm = () => {
        setAircraft(request.data.attributes.aircraft)
        setPhoneNumber(request.data.attributes.phone_number)
        setComments(request.data.attributes.comments)
        setStageTime(request.data.attributes.stage_time)
        setDepartureTime(request.data.attributes.departure_time)
        setArrivalTime(request.data.attributes.arrival_time)
        setErrors([])
    }

    return (
        <section aria-labelledby="request-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="flex">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="request-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Request Information
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Request details and communications.</p>
                    </div>
                    <div className="flex justify-end flex-grow mt-6 sm:mt-4 mr-2">
                        {editing ? (
                            <>
                                <CancelButton cancelHandler={cancelHandler} />
                                <SaveButton submitForm={mutation.mutate} isLoading={mutation.isLoading} />
                            </>
                        ) : (
                            <EditButton setEditing={setEditing} />
                        )}
                    </div>
                </div>

                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <Errors errors={errors} />
                    {editing ? (
                        <RequestCardInput
                            aircraft={aircraft}
                            updateAircraft={updateAircraft}
                            phoneNumber={phoneNumber}
                            updatePhoneNumber={updatePhone}
                            comments={comments}
                            updateComments={updateComments}
                            stageTime={stageTime}
                            updateStageTime={updateStageTime}
                            departureTime={departureTime}
                            updateDepartureTime={updateDepartureTime}
                            arrivalTime={arrivalTime}
                            updateArrivalTime={updateArrivalTime}
                        />
                    ) : (
                        <RequestCardShow request={request} />
                    )}
                </div>
            </div>
        </section>
    )
}
