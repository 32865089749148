import { createContext, useState } from 'react'
import { useCookies } from 'react-cookie'
import { API } from '../lib/API'
import { useQuery } from 'react-query'
import { IUser } from '../types/IUser'

export interface IAuthContext {
    authenticated: boolean
    setAuthenticated: Function
    signOut: Function
    user: IUser | undefined
    setUser: Function
    restoreSession: Function
}

export const AuthContext = createContext<IAuthContext>({
    authenticated: false,
    setAuthenticated: (_value: boolean) => {},
    signOut: () => {},
    user: undefined,
    setUser: (_value: any) => {},
    restoreSession: () => {},
})

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [cookies, setCookie] = useCookies()

    const [user, setUser] = useState()
    const [authenticated, setAuthenticated] = useState(false)

    const fetchProfile = async () => {
        console.log('fetching user')
        const data = await API.profile()
        return await data.json()
    }

    const { refetch } = useQuery(['users', 'profile'], fetchProfile, {
        enabled: false,
        onSuccess: (data) => setUser(data),
    })

    const signOut = () => {
        setAuthenticated(false)
        setCookie('token', '', {
            path: '/',
            sameSite: 'lax',
            domain: window.location.hostname,
        })
        setCookie('user', '', {
            path: '/',
            sameSite: 'lax',
            domain: window.location.hostname,
        })

        window.history.pushState({}, 'Home', '/')
        window.location.reload()
    }

    const restoreSession = () => {
        if (authenticated) return

        if (cookies.token) {
            console.debug('Restoring session from token')
            refetch()
            setAuthenticated(true)
            console.debug('Session restored')
        } else {
            setAuthenticated(false)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                setAuthenticated,
                signOut,
                user,
                setUser,
                restoreSession,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
