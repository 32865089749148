import { RequestType } from '../../../types/RequestType'
import { Link } from 'react-router-dom'
import { GiAirplaneArrival, GiAirplaneDeparture } from 'react-icons/gi'
import { Status } from './Status'
import { Item } from './Item/Item'
import { ItemContent } from './Item/ItemContent'
import { Time } from '../../shared/Time'
import { ItemHeader } from './Item/ItemHeader'
import { DotsVerticalIcon } from '@heroicons/react/outline'
import { borderColor } from '../Dashboard'
import { RequestActions } from '../../Request/RequestCard/RequestActions/RequestActions'
import { Menu } from '@headlessui/react'

export const RequestRow = ({ request }: { request: RequestType }) => {
    if (!request.data.attributes.visible_on_dashboard) {
        return null
    }

    return (
        <li key={request.data.id}>
            <Link to={'/requests/' + request.data.id} className="block hover:bg-gray-50">
                <div
                    className={
                        'flex items-center px-4 pt-2 pb-2 border-solid border-t-2 ' +
                        borderColor(request.data.attributes.status)
                    }
                >
                    <div className="min-w-0 flex-1 flex items-center">
                        <div className="flex-shrink-0">
                            <div>
                                {request.data.attributes['earliest_time'] ===
                                request.data.attributes['arrival_time'] ? (
                                    <GiAirplaneArrival className="h-8 w-8 rounded-full" />
                                ) : (
                                    <GiAirplaneDeparture className="h-8 w-8 rounded-full" />
                                )}
                            </div>
                        </div>
                        <div className="min-w-0 flex-1 items-center px-4 grid grid-cols-5">
                            <div>
                                <p className="text-xl mb-0 pb-0 font-semibold">{request.data.attributes.tail_number}</p>
                                <div className="flex justify-start items-center">
                                    <Status status={request.data.attributes.status} />
                                </div>
                            </div>
                            <Item>
                                <ItemContent>
                                    <Time>{request.data.attributes.stage_time}</Time>
                                </ItemContent>
                                <ItemHeader>Stage Time</ItemHeader>
                            </Item>
                            <Item>
                                <ItemContent>
                                    <Time>{request.data.attributes.departure_time}</Time>
                                </ItemContent>
                                <ItemHeader>Departure Time</ItemHeader>
                            </Item>
                            <Item>
                                <ItemContent>
                                    <Time>{request.data.attributes.arrival_time}</Time>
                                </ItemContent>
                                <ItemHeader>Arrival Time</ItemHeader>
                            </Item>
                            <Item>
                                <ItemContent>{request.data.attributes.comments}</ItemContent>
                                <ItemHeader>Comments</ItemHeader>
                            </Item>
                        </div>
                    </div>
                    <RequestActions request={request}>
                        <Menu.Button>
                            <DotsVerticalIcon className="h-7 w-auto text-gray-400" aria-hidden="true" />
                        </Menu.Button>
                    </RequestActions>
                </div>
            </Link>
        </li>
    )
}
