import { API } from './API'

const CHANNEL = 'RequestsChannel'

export const subscribeMessage = ({ id }: { id: string }) => {
    console.log('Sending subscribeMessage')
    return JSON.stringify({
        command: 'subscribe',
        identifier: identifier({ id: id }),
    })
}

export const loadRequests = (user_id: string) => {
    return message({ id: user_id, action: 'load' })
}

export const message = ({ id, action }: { id: string; action: string }) =>
    JSON.stringify({
        command: 'message',
        data: JSON.stringify({ action: action }),
        identifier: identifier({ id: id }),
    })

interface IdentifierParams {
    id: string
    channel: string
    token?: string
}

const identifier = ({ id }: { id: string }) => {
    let params: IdentifierParams = {
        id: id,
        channel: CHANNEL,
    }
    if (API.token()) params['token'] = API.token()
    return JSON.stringify(params)
}
