import { BUTTON_BASE_CLASSES } from './Button'
import { XCircleIcon } from '@heroicons/react/outline'
import React from 'react'

export const CancelButton = ({ cancelHandler }: { cancelHandler: Function }) => (
    <button
        type="button"
        className={`${BUTTON_BASE_CLASSES} mr-2 self-start bg-white text-grey-700 hover:bg-gray-50`}
        onClick={() => cancelHandler()}
    >
        <XCircleIcon className="mr-1 h-5 w-5 text-red-400 group-hover:text-red-500" aria-hidden="true" />
        Cancel
    </button>
)
