import Avatar from 'react-avatar'
import React, { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

export const FBORequestAvatar = ({ size = '42', name = undefined }: { size?: string; name?: string }) => {
    const auth = useContext(AuthContext)

    if (!name) {
        if (!auth.user) {
            return null
        }

        name = auth?.user?.data?.attributes?.avatar
    }

    if (!name) name = 'User Name'

    // Generate a random color based on a users name
    const seed = name.charCodeAt(0) + name.charCodeAt(1) + name.charCodeAt(2)
    const color = '#' + Math.floor(seed * 0xfffff).toString(16)

    return <Avatar name={name} color={color} round={true} size={size} />
}
