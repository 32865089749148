import { API } from '../lib/API'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import React, { useContext, useState } from 'react'
import { IAircraft } from '../types/IAircraft'
import { toast } from '../lib/toast'
import { PlusIcon } from '@heroicons/react/outline'
import { Errors } from './shared/Errors'
import { AuthContext } from '../contexts/AuthContext'
import { ButtonLabelWithReplacementLoadingIcon } from './shared/ButtonLabelWithReplacementLoadingIcon'

export const Aircraft = () => {
    const [tailNumber, setTailNumber] = useState('')
    const [errors, setErrors] = useState([])
    const updateTailNumber = (event: any) => {
        event.preventDefault()
        setTailNumber(event.target.value)
    }

    const fetchAircraft = async () => {
        console.log('fetching aircraft')
        const data = await API.aircraft()
        return await data?.json()
    }

    const submitAircraft = (event: any) => {
        event.preventDefault()
        mutation.mutate()
    }

    const { data: aircraft, error, isError, isLoading } = useQuery('aircraft', fetchAircraft)
    const auth = useContext(AuthContext)

    const queryClient = useQueryClient()
    const mutation = useMutation(async () => {
        if (!auth.user) return
        setErrors([])
        const response = await API.addAircraft({ tailNumber: tailNumber, userId: auth?.user?.data?.id })

        const responseJson = await response.json()

        if (API.successfulResponse(response.status)) {
            queryClient.invalidateQueries('aircraft')
            toast.success('Aircraft added')
            setTailNumber('')
            return
        }
        setErrors(responseJson.errors)
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        // @ts-ignore
        return <div>Error! {error?.message}</div>
    }

    if (aircraft?.error) {
        return null
    }

    return (
        <>
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <h1 className="text-2xl font-bold text-gray-900">Aircraft Listing</h1>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    <div className="bg-white shadow sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300 py-5">
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {aircraft?.map((plane: IAircraft, index: number) => (
                                    <tr
                                        key={plane.data.attributes.id}
                                        className={index % 2 === 0 ? undefined : 'bg-gray-50'}
                                    >
                                        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                            {plane.data.attributes.tail_number}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="lg:col-start-3 lg:col-span-1">
                    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-4">
                        <h2 id="form-title" className="text-lg font-medium text-gray-900">
                            Add Aircraft
                        </h2>
                        <Errors errors={errors} />
                        <form className="mt-1 sm:flex sm:items-center" onSubmit={submitAircraft}>
                            <div className="w-full sm:max-w-xs">
                                <input
                                    type="text"
                                    name="tail_number"
                                    id="tail_number"
                                    className="shadow-sm focus:ring-brand-primary-500 focus:border-brand-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    placeholder="N515NA"
                                    onChange={updateTailNumber}
                                />
                            </div>
                            <button
                                type="submit"
                                className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-brand-primary-600 hover:bg-brand-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                disabled={mutation.isLoading}
                            >
                                <ButtonLabelWithReplacementLoadingIcon
                                    loading={mutation.isLoading}
                                    iconClass="ml-1 h-4 w-4 text-white group-hover:text-gray-500"
                                    size={16}
                                    icon={PlusIcon}
                                >
                                    Add
                                </ButtonLabelWithReplacementLoadingIcon>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
