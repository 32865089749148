import React from 'react'

export const TextInput = ({
    id,
    value,
    onChange,
    placeholder,
    className,
}: {
    id: string
    onChange: Function
    value?: string
    placeholder?: string
    className?: string
}) => (
    <div>
        <div className="mt-1 border-b border-gray-300 focus:border-brand-primary-600">
            <input
                type="text"
                name={id}
                id={id}
                className={
                    'block w-full border-0 border-b border-transparent bg-gray-50 focus:border-brand-primary-600 focus:ring-0 sm:text-sm' +
                    ' ' +
                    className
                }
                value={value}
                placeholder={placeholder}
                onChange={(event) => onChange(event)}
            />
        </div>
    </div>
)
