import { API } from '../lib/API'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Logo } from './shared/Logo'
import { PRIMARY_BUTTON_COLOR } from '../colors'
import { LockClosedIcon, MailIcon } from '@heroicons/react/outline'
import { ButtonLabelWithLoadingIcon } from './shared/ButtonLabelWithLoadingIcon'

export const SignUp = () => {
    const navigate = useNavigate()

    const [email, setEmail] = useState('test@example.com')
    const [password, setPassword] = useState('password')
    const [passwordConfirmation, setPasswordConfirmation] = useState('password')
    const [submitting, setSubmitting] = useState(false)
    const [errorMessages, setErrorMessages] = useState({})

    const emailInputOnChange = (event: any) => setEmail(event.target.value)
    const passwordInputOnChange = (event: any) => setPassword(event.target.value)
    const passwordConfirmationInputOnChange = (event: any) => setPasswordConfirmation(event.target.value)

    const submitForm = async (event: any) => {
        setSubmitting(true)
        setErrorMessages({})
        event.preventDefault()

        console.log('Submitting')

        const response = await API.signUp({
            email: email,
            password: password,
            passwordConfirmation: passwordConfirmation,
        })

        const responseJson = await response.json()

        setSubmitting(false)
        if (response.status !== 201) {
            console.log(responseJson.errors)
            setErrorMessages(responseJson.errors)
            return
        }
        navigate('/sign_in')
    }

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <Logo className="mx-auto h-16 w-auto" />
                <h2 className="mt-1 text-center text-3xl font-extrabold text-gray-900">Sign up for your account</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="h-min-8 mb-4 text-red-700 text-center">
                        {Object.keys(errorMessages).map((error) => {
                            // @ts-ignore
                            // TODO Clean this up
                            return <li>{`${error} ${errorMessages[error]}`}</li>
                        })}
                    </div>
                    <form className="space-y-6" onSubmit={(event) => submitForm(event)}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none pl-10 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-primary-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={emailInputOnChange}
                                    placeholder="you@example.com"
                                />
                            </div>
                            <div className="mt-1"></div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    placeholder="password"
                                    onChange={passwordInputOnChange}
                                    className="appearance-none pl-10 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-primary-500 focus:border-brand-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password Confirmation
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    type="password"
                                    required
                                    placeholder="confirm password"
                                    onChange={passwordConfirmationInputOnChange}
                                    className="appearance-none pl-10 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-primary-500 focus:border-brand-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={submitting}
                                className={
                                    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium ' +
                                    PRIMARY_BUTTON_COLOR
                                }
                            >
                                <div className="flex w-full">
                                    <ButtonLabelWithLoadingIcon loading={submitting}>
                                        Sign Up
                                    </ButtonLabelWithLoadingIcon>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
