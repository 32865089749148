import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { MenuAlt1Icon } from '@heroicons/react/outline'
import { Outlet } from 'react-router-dom'
import { UpperBar } from './UpperBar'
import { LowerBar } from './LowerBar'
import { Logo } from '../shared/Logo'

export const TopBar = ({ setSidebarOpen = () => {} }: { setSidebarOpen?: Function }) => {
    const auth = useContext(AuthContext)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    useEffect(() => {
        if (!auth.user) {
            return
        }
        let fname = auth?.user?.data?.attributes?.first_name
        let lname = auth?.user?.data?.attributes?.last_name
        if (fname === null && lname === null) {
            fname = auth?.user?.data?.attributes?.email
            lname = ''
        } else {
            fname = fname === null ? '' : fname
            lname = lname === null ? '' : lname
        }
        if ((fname.trim() + lname.trim()).length === 0) {
            fname = auth?.user?.data?.attributes?.email
            lname = ''
        }
        setFirstName(fname)
        setLastName(lname)
    }, [auth, auth.user])
    const WIDTH = auth.authenticated ? 'lg:pl-64' : 'w-full'

    return (
        <div className={`flex flex-col flex-1 ${WIDTH}`}>
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
                <ShowIfNotAuthenticated authenticated={auth.authenticated}>
                    <Logo />
                </ShowIfNotAuthenticated>
                <ShowIfAuthenticated authenticated={auth.authenticated}>
                    <button
                        type="button"
                        className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </ShowIfAuthenticated>
                <UpperBar fullName={firstName + ' ' + lastName} authenticated={auth.authenticated} />
            </div>
            <main className="flex-1 pb-8">
                <div className="bg-white shadow">
                    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                        {auth.authenticated ? <LowerBar firstName={firstName} lastName={lastName} /> : null}
                    </div>
                </div>

                <div className="mt-8">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">{<Outlet />}</div>
                </div>
            </main>
        </div>
    )
}

const ShowIfAuthenticated = ({ authenticated, children }: { authenticated: boolean; children: React.ReactNode }) => {
    return authenticated ? <>{children}</> : null
}

const ShowIfNotAuthenticated = ({ authenticated, children }: { authenticated: boolean; children: React.ReactNode }) => {
    return !authenticated ? <>{children}</> : null
}
