import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment, useContext } from 'react'
import { ProfileMenuItem, profileMenuItemCss } from './ProfileMenuItem'
import { classNames } from '../../layouts/AppLayout/AppLayout'
import { AuthContext } from '../../contexts/AuthContext'
import { FBORequestAvatar } from '../shared/Avatar'

export interface ProfileMenuItemType {
    name: string
    url: string
    key?: number
}

export const ProfileMenu = ({ fullName }: { fullName: string }) => {
    const profileMenuItems: ProfileMenuItemType[] = [
        { name: 'Your Profile', url: '/profile' },
        { name: 'Settings', url: '/settings' },
    ]

    return (
        <Menu as="div" className="ml-3 relative">
            <div>
                <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                    <FBORequestAvatar />
                    <span className="hidden ml-2 text-gray-700 text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>
                        {fullName}
                    </span>
                    <ChevronDownIcon
                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {profileMenuItems.map((item, index) => (
                        <ProfileMenuItem key={index} item={item} />
                    ))}
                    <SignOutLink />
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

const SignOutLink = () => {
    const auth = useContext(AuthContext)

    return (
        <Menu.Item>
            {({ active }) => (
                <button
                    type="button"
                    onClick={() => auth.signOut()}
                    className={classNames(active ? 'bg-gray-100' : '', profileMenuItemCss + ' w-full text-left')}
                >
                    Sign out
                </button>
            )}
        </Menu.Item>
    )
}
