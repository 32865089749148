import { FuelTruck } from '../../types/FuelTruck'
import { Content } from '../shared/Content'
import { CancelButton } from '../shared/CancelButton'
import { SaveButton } from '../shared/SaveButton'
import { EditButton } from '../shared/EditButton'
import { ShowCard } from '../shared/ShowCard'
import { FuelTruckInput } from './FuelTruckInput'
import { FuelTruckShow } from './FuelTruckShow'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { API } from '../../lib/API'
import { toast } from '../../lib/toast'
import { useParams } from 'react-router-dom'
import { PhxErrors } from '../shared/Errors'

interface FuelTruckCardProps {
    fuelTruck: FuelTruck
    editing: boolean
    setEditing: Function
}

export const FuelTruckDetails = ({ fuelTruck, editing, setEditing }: FuelTruckCardProps) => {
    const { id } = useParams()

    const [name, setName] = useState(fuelTruck.data.attributes.name)
    const [type, setType] = useState(fuelTruck.data.attributes.type)
    const [meter, setMeter] = useState(fuelTruck.data.attributes.meter)
    const [level, setLevel] = useState(fuelTruck.data.attributes.level)
    const [capacity, setCapacity] = useState(fuelTruck.data.attributes.capacity)
    const [errors, setErrors] = useState({})

    const updateName = (event: { target: { value: any } }) => setName(event.target.value)
    const updateType = (event: { target: { value: any } }) => setType(event.target.value)
    const updateMeter = (event: { target: { value: any } }) => setMeter(event.target.value)
    const updateLevel = (event: { target: { value: any } }) => setLevel(event.target.value)
    const updateCapacity = (event: { target: { value: any } }) => setCapacity(event.target.value)

    const resetForm = () => {
        setName(fuelTruck.data.attributes.name)
        setType(fuelTruck.data.attributes.type)
        setMeter(fuelTruck.data.attributes.meter)
        setLevel(fuelTruck.data.attributes.level)
        setCapacity(fuelTruck.data.attributes.capacity)
        setErrors({})
    }

    const cancelHandler = () => {
        resetForm()
        setEditing(false)
    }

    const queryClient = useQueryClient()

    const mutation = useMutation(
        () => {
            if (!id) throw new Error('no params id')

            return API.patch(
                ['fuel_trucks', id],
                {
                    fuel_truck: {
                        name: name,
                        type: type,
                        meter: meter,
                        level: level,
                        capacity: capacity,
                    },
                },
                true
            )
        },
        {
            onSuccess: async (data) => {
                if (API.successfulResponse(data.status)) {
                    setEditing(false)
                    toast.success('Truck has been updated')
                    console.debug('invalidating requests')
                    await queryClient.invalidateQueries(['fuel_trucks', id])
                    setErrors([])
                    return
                }

                const responseJson = await data.json()
                setErrors(responseJson?.errors)
            },
        }
    )

    return (
        <Content.ColumnSection>
            <div className="flex">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="request-information-title" className="text-lg leading-6 font-medium text-gray-900">
                        Details
                    </h2>
                </div>
                <div className="flex justify-end flex-grow mt-6 sm:mt-4 mr-2">
                    {editing ? (
                        <>
                            <CancelButton cancelHandler={cancelHandler} />
                            <SaveButton submitForm={mutation.mutate} isLoading={mutation.isLoading} />
                        </>
                    ) : (
                        <EditButton setEditing={setEditing} />
                    )}
                </div>
            </div>

            <ShowCard>
                <PhxErrors errors={errors} />
                <ShowCard.ContentContainer>
                    {editing ? (
                        <FuelTruckInput
                            name={name}
                            updateName={updateName}
                            type={type}
                            updateType={updateType}
                            meter={meter}
                            updateMeter={updateMeter}
                            level={level}
                            updateLevel={updateLevel}
                            capacity={capacity}
                            updateCapacity={updateCapacity}
                        />
                    ) : (
                        <FuelTruckShow fuelTruck={fuelTruck} />
                    )}
                </ShowCard.ContentContainer>
            </ShowCard>
        </Content.ColumnSection>
    )
}
