import React from 'react'
import { API } from '../../../../../lib/API'
import { useQuery } from 'react-query'
import { AircraftTextInput } from './AircraftTextInput'
import { AircraftComboboxInput } from './AircraftComboboxInput'

export const AircraftInput = ({ aircraft, updateAircraft }: { aircraft: string; updateAircraft: Function }) => {
    const fetchAircraft = async () => {
        console.log('fetching aircraft')
        const data = await API.aircraft()
        const responseJson = await data?.json()
        return responseJson
    }

    const {
        data: availableAircraft,
        error,
        isError,
        isLoading,
    } = useQuery('aircraft', fetchAircraft, { initialData: [] })

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }

    // if (availableAircraft.error || !availableAircraft.data) {
    //     return null
    // }

    const selectableAircraft =
        availableAircraft === undefined || isError || availableAircraft?.length === 0 || availableAircraft?.error

    return (
        <>
            {selectableAircraft ? (
                <AircraftTextInput updateAircraft={updateAircraft} aircraft={aircraft} />
            ) : (
                <AircraftComboboxInput
                    aircraft={aircraft}
                    updateAircraft={updateAircraft}
                    availableAircraft={availableAircraft}
                />
            )}
        </>
    )
}
