import { Menu, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { LocationMarkerIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import { RequestType } from '../../../../types/RequestType'
import { useQueryClient } from 'react-query'
import { MenuItem } from './MenuItem'
import { MenuSection } from './MenuSection'
import { AdvanceableStatuses } from './AdvanceableStatuses'
import { RejectableStatuses } from './RejectableStatuses'
import { DashboardVisibility } from './DashboardVisibility'
import { requestActionMutation } from '../../../../lib/requestActionMutation'
import { EmployeeOnly } from '../../../shared/EmployeeOnly'

interface RequestActionsProps {
    request: RequestType
    children: React.ReactNode
    className?: string
}

export const RequestActions = ({ request, children, ...props }: RequestActionsProps) => {
    const mutation = requestActionMutation(useQueryClient())

    // I think we might be safe to not show actions once an request is in complete status?
    // It may be needed to be shown/hidden from dashboard?
    // What about tracked?
    // Should 'actions' be able to correct if someone clicks 'complete' and it's not?

    return (
        <Menu
            as="div"
            className="relative inline-block text-left"
            onClick={(e: { preventDefault: () => void }) => e.preventDefault()}
        >
            <div {...props}>{children}</div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none overflow-visible z-10">
                    <MenuSection>
                        <MenuItem onClick={() => window.open(request.data.attributes.flight_aware_url)}>
                            <LocationMarkerIcon
                                className="mr-3 h-5 w-5 text-brand-primary-400 group-hover:text-brand-primary-500 pointer-events-none"
                                aria-hidden="true"
                            />
                            Track Aircraft
                            <ExternalLinkIcon
                                className="ml-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </MenuItem>
                        <EmployeeOnly>
                            <DashboardVisibility
                                showOnDashboard={request.data.attributes.visible_on_dashboard}
                                id={request.data.attributes.id}
                                mutation={mutation}
                            />
                        </EmployeeOnly>
                    </MenuSection>
                    <EmployeeOnly>
                        <AdvanceableStatuses
                            status={request.data.attributes.status}
                            id={request.data.attributes.id}
                            mutation={mutation}
                        />
                    </EmployeeOnly>
                    <RejectableStatuses
                        status={request.data.attributes.status}
                        id={request.data.attributes.id}
                        mutation={mutation}
                    />
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
