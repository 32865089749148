import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'
import React from 'react'
import { Link } from 'react-router-dom'

// FIXME move this up?

export const Breadcrumbs = () => {
    const pages = [
        { name: 'Requests', href: '/requests', current: false },
        { name: 'This Request', href: '#', current: true },
    ]

    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
                <li>
                    <div>
                        <Link to="/" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {pages.map((page) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <BreadcrumbLinkWrapper page={page}>{page.name}</BreadcrumbLinkWrapper>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}

interface PageType {
    name: string
    href: string
    current: boolean
}

const BreadcrumbLinkWrapper = ({ children, page }: { children: React.ReactNode; page: PageType }) => {
    let className = 'ml-4 text-sm font-medium text-gray-500 '
    className += page.current ? '' : 'hover:text-gray-700'
    className += page.current ? 'text-gray-700' : ''

    return page.current ? (
        <div className={className}>{children}</div>
    ) : (
        <Link className={className} to={page.href}>
            {children}
        </Link>
    )
}
