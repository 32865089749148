import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import React from 'react'
import { PaperAirplaneIcon } from '@heroicons/react/outline'
import { API } from '../../lib/API'
import { useQuery } from 'react-query'
import { GiAirplaneArrival, GiAirplaneDeparture } from 'react-icons/gi'
import { Link } from 'react-router-dom'

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

export const EmployeeHome = () => {
    const fetchRollupStats = async () => {
        console.log('fetching stats')
        const data = await API.get(['reports', 'rollup'])
        return await data?.json()
    }

    const { data, error, isError, isLoading } = useQuery(['stats', 'rollup'], fetchRollupStats)

    const stats = [
        {
            id: 1,
            name: 'Total Requests',
            stat: data?.requests?.count,
            icon: PaperAirplaneIcon,
            change: data?.requests?.change_per_cent || data?.requests?.change,
            changeType: data?.requests?.change_type,
        },
        {
            id: 2,
            name: 'Arrivals',
            stat: data?.arrivals?.count,
            icon: GiAirplaneArrival,
            change: data?.arrivals?.change_per_cent || data?.arrivals?.change,
            changeType: data?.arrivals?.change_type,
        },
        {
            id: 3,
            name: 'Departures',
            stat: data?.departures?.count,
            icon: GiAirplaneDeparture,
            change: data?.departures?.change_per_cent || data?.departures?.change,
            changeType: data?.departures?.change_type,
        },
    ]

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }
    if (data.error) {
        return null
    }

    return (
        <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Last 30 days</h3>

            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {stats.map((item) => (
                    <div
                        key={item.id}
                        className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                    >
                        <dt>
                            <div className="absolute bg-brand-primary-500 rounded-md p-3">
                                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                            <p
                                className={classNames(
                                    item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}
                            >
                                {item.changeType === 'increase' ? (
                                    <ArrowSmUpIcon
                                        className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <ArrowSmDownIcon
                                        className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                                        aria-hidden="true"
                                    />
                                )}

                                <span className="sr-only">
                                    {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by
                                </span>
                                {item.change}
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm">
                                    <span className="font-medium text-brand-primary-600 hover:text-brand-primary-700">
                                        <Link to="/requests/all">View all</Link>
                                    </span>
                                </div>
                            </div>
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    )
}
