import { CardTitle } from './shared/CardTitle'
import { Card } from './shared/Card'
import React from 'react'
import { PhoneLink } from './shared/PhoneLink'
import { HiOutlineMail } from 'react-icons/hi'
import { ChatIcon, PhoneIcon } from '@heroicons/react/outline'

export const Support = () => (
    <>
        <CardTitle>Support</CardTitle>
        <Card>
            <div className="p-6">
                <p className="text-sm mb-8">
                    Our team is dedicated to providing our users with the best possible customer service. We'd love to
                    hear any feedback you may have, bugs you've ran into, or improvements you'd like to see. Please use
                    one of the options below to contact us.
                </p>

                <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <HiOutlineMail className="h-6 w-auto text-grey-500 pr-1" />
                    Email
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                    <a
                        className="text-sm text-brand-primary hover:text-brand-primary-700 font-mono"
                        href="mailto:support@fborequest.com"
                    >
                        support@fborequest.com
                    </a>
                    <br />
                    <a
                        className="text-sm text-brand-primary hover:text-brand-primary-700 font-mono"
                        href="mailto:rick@fborequest.com"
                    >
                        rick@fborequest.com
                    </a>
                    <br />
                    <a
                        className="text-sm text-brand-primary hover:text-brand-primary-700 font-mono"
                        href="mailto:brian@fborequest.com"
                    >
                        brian@fborequest.com
                    </a>
                </dd>

                <dt className="text-sm font-medium text-gray-500 flex items-center mt-4">
                    <ChatIcon className="h-6 w-auto text-grey-500 pr-1" />
                    Chat
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                    <a
                        href="https://join.slack.com/t/fborequest/shared_invite/zt-1bb6ls6iq-7lcsMezEhoyvW19eY5yRWg"
                        className="text-sm text-brand-primary hover:text-brand-primary-700"
                    >
                        Join via slack
                    </a>
                </dd>

                <dt className="text-sm font-medium text-gray-500 flex items-center mt-4">
                    <PhoneIcon className="h-6 w-auto text-grey-500 pr-1" />
                    Phone
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                    <PhoneLink>212.867.5309</PhoneLink>
                </dd>
            </div>
        </Card>
    </>
)
