import { useCallback, useEffect, useState } from 'react'
import { Logo } from '../shared/Logo'
import { format } from 'date-fns'
import { CogIcon, CloudIcon } from '@heroicons/react/outline'
import { ReadyState } from 'react-use-websocket'
import { Link, NavLink } from 'react-router-dom'
import { PrimaryButton } from '../shared/PrimaryButton'

export const Header = ({ readyState }: { readyState: ReadyState }) => {
    const [currentTime, setCurrentTime] = useState(new Date())
    const [clockRunning, setClockRunning] = useState(false)

    const updateCurrentTime = useCallback(() => {
        setClockRunning(true)
        setCurrentTime(new Date())
        setTimeout(updateCurrentTime, 1000)
    }, [])

    useEffect(() => {
        if (!clockRunning) {
            updateCurrentTime()
        }
    }, [clockRunning, updateCurrentTime])

    return (
        <div className="flex bg-white drop-shadow shadow h-16 justify-center items-center">
            <div className="flex-1">
                <div className="inline-flex ml-4">
                    <Link to="/">
                        <Logo className="h-12 w-auto" />
                    </Link>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center">
                <div className="text-sm text-gray-400 mb-0 pb-0">{format(currentTime, 'MMMM do, yyyy')}</div>
                <div className="text-4xl text-gray-600 pt-0 mt-0">{format(currentTime, 'HH:mm:ss')}</div>
            </div>
            <div className="flex-1">
                <div className="flex justify-end align-bottom">
                    <div className="invisible lg:visible mr-2 mt-0 pt-0">
                        <NavLink to="/requests/new">
                            <PrimaryButton>New Request</PrimaryButton>
                        </NavLink>
                    </div>
                    <ConnectionStatusIcon readyState={readyState} />
                    <CogIcon className="h-6 w-6 text-gray-500 mr-3 self-center" />
                </div>
            </div>
        </div>
    )
}

const ConnectionStatusIcon = ({ readyState }: { readyState: ReadyState }) => {
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'text-yellow-400',
        [ReadyState.OPEN]: 'text-emerald-500',
        [ReadyState.CLOSING]: 'text-yellow-400',
        [ReadyState.CLOSED]: 'text-red-600',
        [ReadyState.UNINSTANTIATED]: 'text-red-600',
    }[readyState]

    return <CloudIcon className={'h-6 w-6 mr-3 self-center ' + connectionStatus} />
}
