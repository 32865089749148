import React from 'react'
import { format as formatTime, parseISO } from 'date-fns'

interface TimeType {
    format?: string
    children: string
}

// See https://date-fns.org/v2.28.0/docs/format for more options
export const TIME_FORMAT = 'H:mm'
export const DATE_FORMAT = 'MM/dd/yy'

export const Time = ({ format = DATE_FORMAT + ' ' + TIME_FORMAT, children }: TimeType) => {
    if (!children) {
        return <span className="text-sm italic text-gray-300">none</span>
    }
    return <time dateTime={children}>{formatTime(parseISO(children), format)}</time>
}
