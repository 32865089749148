import React from 'react'
import { CheckIcon, XIcon } from '@heroicons/react/solid'
import { RequestType } from '../../types/RequestType'
import { PRIMARY_BUTTON_COLOR } from '../../colors'
import { BUTTON_BASE_CLASSES } from '../shared/Button'
import { EmployeeOnly } from '../shared/EmployeeOnly'
import { requestActionMutation, RequestActionType } from '../../lib/requestActionMutation'
import { useQueryClient } from 'react-query'
import { ButtonLabelWithLoadingIcon } from '../shared/ButtonLabelWithLoadingIcon'

export const Timeline = ({ request }: { request: RequestType }) => {
    const incompleteColor = 'bg-gray-400'
    const completeColor = 'bg-green-600'
    const rejectedColor = 'bg-red-700'

    interface TimelineEntry {
        id: number
        event: string
        status: boolean
    }

    let timeline: TimelineEntry[] = []

    // Request rejected
    if (request.data.attributes['rejected?']) {
        timeline = [
            {
                id: 1,
                event: 'Received',
                status: true,
            },
            {
                id: 3,
                event: 'Rejected',
                status: true,
            },
        ]
    } else {
        // Request accepted
        timeline = [
            {
                id: 1,
                event: 'Received',
                status: true,
            },
            {
                id: 2,
                event: 'Acknowledged',
                status: request.data.attributes['acknowledged?'] || request.data.attributes['completed?'],
            },
            {
                id: 3,
                event: 'In Progress',
                status: request.data.attributes['completed?'],
            },
            {
                id: 4,
                event: 'Completed',
                status: request.data.attributes['completed?'],
            },
        ]
    }

    return (
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                Timeline
            </h2>

            {/* Activity Feed */}
            <div className="mt-6 flow-root">
                <ul className="-mb-8">
                    {timeline.map(({ id, event, status }, itemIdx) => {
                        //  Colors and icon for accepted requests
                        let bgColor = status ? completeColor : incompleteColor
                        let icon = <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                        // Color and icons for rejected requests
                        if (event === 'Rejected') {
                            bgColor = rejectedColor
                            icon = <XIcon className="w-5 h-5 text-white" aria-hidden="true" />
                        }

                        return (
                            <li key={id}>
                                <div className="relative pb-8">
                                    {itemIdx !== timeline.length - 1 ? (
                                        <span
                                            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                            aria-hidden="true"
                                        />
                                    ) : null}
                                    <div className="relative flex space-x-3">
                                        <div>
                                            <span
                                                className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${bgColor}`}
                                            >
                                                {icon}
                                            </span>
                                        </div>
                                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                            <div>
                                                <p className="text-sm text-gray-500">{event} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <EmployeeOnly>
                <AdvancedButton id={request.data.attributes.id} status={request.data.attributes.status} />
            </EmployeeOnly>
        </div>
    )
}

const AdvancedButton = ({ status, id }: { status: string; id: string }) => {
    const mutation = requestActionMutation(useQueryClient())

    let label = null
    let action = '' as RequestActionType
    switch (status) {
        case 'new':
            label = 'Acknowledged'
            action = 'acknowledge'
            break
        case 'acknowledged':
            label = 'Completed'
            action = 'complete'
            break
    }

    if (!label || action.length === 0) {
        return null
    }

    let onClickHandler = () => {
        mutation.mutate({ action: action, id: id })
    }

    return (
        <div className="mt-6 flex flex-col justify-stretch">
            <button type="button" onClick={onClickHandler} className={`${BUTTON_BASE_CLASSES} ${PRIMARY_BUTTON_COLOR}`}>
                <ButtonLabelWithLoadingIcon loading={mutation.isLoading}>Advance to {label}</ButtonLabelWithLoadingIcon>
            </button>
        </div>
    )
}
