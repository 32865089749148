import { PrimaryButton } from './PrimaryButton'
import { ButtonLabelWithReplacementLoadingIcon } from './ButtonLabelWithReplacementLoadingIcon'
import { SaveIcon } from '@heroicons/react/outline'
import React from 'react'

export const SaveButton = ({ submitForm, isLoading }: { submitForm: Function; isLoading: boolean }) => (
    <PrimaryButton onClick={() => submitForm()}>
        <ButtonLabelWithReplacementLoadingIcon loading={isLoading} icon={SaveIcon} iconInFront={true}>
            Save
        </ButtonLabelWithReplacementLoadingIcon>
    </PrimaryButton>
)
