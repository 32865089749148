import { MenuSection } from './MenuSection'
import { Acknowledge, ActionProps } from './Acknowledge'
import { Complete } from './Complete'
import React from 'react'

export const AdvanceableStatuses = ({ status, id, mutation }: ActionProps) => {
    if (status === 'new' || status === 'acknowledged') {
        return (
            <MenuSection>
                <Acknowledge status={status} id={id} mutation={mutation} />
                <Complete status={status} id={id} mutation={mutation} />
            </MenuSection>
        )
    }

    return null
}
