import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

interface EmployeeOnlyProps {
    employeeOnly?: boolean
    children: React.ReactNode
}

export const EmployeeOnly = ({ employeeOnly = true, children }: EmployeeOnlyProps) => {
    const auth = useContext(AuthContext)

    const isEmployee = () => {
        if (!auth.authenticated) {
            return false
        }

        if (!auth.user) {
            return false
        }

        return auth?.user?.data?.attributes?.employee
    }

    if (!employeeOnly) {
        return <>{children}</>
    }

    if (isEmployee()) {
        return <>{children}</>
    }

    return null
}
