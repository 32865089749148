import { toast as toastify } from 'react-toastify'
import React from 'react'
import { SuccessToast } from '../components/Toasts/SuccessToast'
import { ErrorToast } from '../components/Toasts/ErrorToast'
import { UnauthorizedToast } from '../components/Toasts/UnauthorizedToast'

// Override for react-toastify to consistently
// render tailwind styled toasts
//
// Usage
// import { toast } from '../../lib/toast'
//
// toast.success() - just shows 'Successfully Saved'
//
// toast.success('this is the body message')
//
// toast.success('this is the body message', {title: 'We can also change the title'})

function toast() {}

toast.success = (body?: string, opts?: { title?: string }) => {
    toastify.success(<SuccessToast title={opts?.title} body={body} />, { icon: false })
}

toast.error = (body?: string, opts?: { title?: string }) => {
    toastify.error(<ErrorToast title={opts?.title} body={body} />, { icon: false })
}

toast.unauthorized = (body?: string, opts?: { title?: string }) => {
    toastify.warn(<UnauthorizedToast title={opts?.title} body={body} />, { icon: false })
}

export { toast }
