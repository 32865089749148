export const Logo = ({
    light = false,
    className = 'mt-3 ml-6 h-12 w-auto',
}: {
    light?: boolean
    className?: string
}) => {
    const path = light ? '/images/logo-light.png' : '/images/logo.png'
    return <img className={className} src={path} alt="FBO Request logo" />
}
