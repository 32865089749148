import { InputItem } from '../Request/RequestCard/RequestCardInput/InputItem'
import { TextInput } from '../shared/TextInput'
import React from 'react'

interface FuelTruckEditProps {
    name: string
    updateName: Function
    type: string
    updateType: Function
    meter: number
    updateMeter: Function
    level: number
    updateLevel: Function
    capacity: number
    updateCapacity: Function
}

export const FuelTruckInput = ({
    name,
    updateName,
    type,
    updateType,
    meter,
    updateMeter,
    level,
    updateLevel,
    capacity,
    updateCapacity,
}: FuelTruckEditProps) => {
    return (
        <>
            <InputItem label="Name">
                <TextInput id="name" value={name} onChange={updateName} />
            </InputItem>
            <InputItem label="Type">
                <select
                    id="type"
                    name="type"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-gray-50 border-l-0 border-t-0 border-r-0 border-b-gray-300 focus:outline-none focus:ring-brand-primary-500 focus:border-brand-primary-500 sm:text-sm"
                    value={type}
                    onChange={(event) => updateType(event)}
                >
                    <option>JET-A</option>
                    <option>100LL</option>
                    <option>MO-GAS</option>
                </select>
            </InputItem>
            <InputItem label="Meter">
                <TextInput id="meter" value={meter.toString()} onChange={updateMeter} />
            </InputItem>
            <InputItem label="Level">
                <TextInput id="level" value={level.toString()} onChange={updateLevel} />
            </InputItem>
            <InputItem label="Capacity">
                <TextInput id="capacity" value={capacity.toString()} onChange={updateCapacity} />
            </InputItem>
        </>
    )
}
