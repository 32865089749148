import React from 'react'
import { Link } from 'react-router-dom'
import { PhoneLink } from '../shared/PhoneLink'
import { CheckCircleIcon } from '@heroicons/react/outline'

export const Submitted = () => (
    <section aria-labelledby="new-request-title">
        <div className="bg-white shadow sm:rounded-lg">
            <div className="flex">
                <div className="px-4 py-5 sm:px-6 flex items-center">
                    <CheckCircleIcon className="h-8 w-auto text-green-600" />
                    <h2 id="new-request-title" className="text-lg leading-6 pl-1 font-medium text-gray-900">
                        <p>Service Request Submitted</p>
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <p>Your request has been submitted to Mid Island Air Service.</p>
                <p className="mt-4">
                    For more information or to make updates to your request please contact us at{' '}
                    <PhoneLink>631-281-5400</PhoneLink>.
                </p>
                <p className="mt-4">
                    <Link to="/sign_up" className="text-brand-primary hover:text-brand-primary-700">
                        Create an account
                    </Link>{' '}
                    to manage your future requests.
                </p>
            </div>
        </div>
    </section>
)
