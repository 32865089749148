import { RequestType } from '../../../types/RequestType'
import { Time } from '../../shared/Time'
import React from 'react'
import { capitalizeFirstLetter } from '../../../lib/capitalizeFirstLetter'
import { PhoneLink } from '../../shared/PhoneLink'
import { EmployeeOnly } from '../../shared/EmployeeOnly'

export const RequestCardShow = ({ request }: { request: RequestType }) => (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Aircraft</dt>
            <dd className="mt-1 text-sm text-gray-900">{request.data.attributes.aircraft}</dd>
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Stage Time</dt>
            <dd className="mt-1 text-sm text-gray-900">
                <Time>{request.data.attributes.stage_time}</Time>
            </dd>
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Departure Time</dt>
            <dd className="mt-1 text-sm text-gray-900">
                <Time>{request.data.attributes.departure_time}</Time>
            </dd>
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Arrival Time</dt>
            <dd className="mt-1 text-sm text-gray-900">
                <Time>{request.data.attributes.arrival_time}</Time>
            </dd>
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Phone</dt>
            <dd className="mt-1 text-sm text-gray-900">
                <PhoneLink>{request.data.attributes.phone_number}</PhoneLink>
            </dd>
        </div>
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 text-sm text-gray-900">{capitalizeFirstLetter(request.data.attributes.status)}</dd>
        </div>
        <EmployeeOnly>
            <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Visible On Dashboard</dt>
                <dd className="mt-1 text-sm text-gray-900">
                    {request.data.attributes.visible_on_dashboard ? 'Yes' : 'No'}
                </dd>
            </div>
        </EmployeeOnly>
        <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Comments</dt>
            <dd className="mt-1 text-sm text-gray-900">{request.data.attributes.comments}</dd>
        </div>
    </dl>
)
