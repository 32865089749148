import { CardTitle } from '../shared/CardTitle'
import React from 'react'
import { API } from '../../lib/API'
import { useQuery } from 'react-query'
import { FuelRequestsTable } from './FuelRequestsTable'

export const AllFuelRequests = () => {
    const fetchFuelRequests = async () => {
        const data = await API.fuelRequests()
        return await data.json()
    }

    const { data, error, isError, isLoading } = useQuery('fuel_requests', fetchFuelRequests)

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (isError) {
        // @ts-ignore
        return <div>Error! {error.message}</div>
    }
    if (data.error) {
        return null
    }

    return (
        <>
            <CardTitle>All Fuel Requests</CardTitle>
            <FuelRequestsTable data={data.data} />
        </>
    )
}
