import { CheckCircleIcon, ExclamationCircleIcon, ThumbUpIcon } from '@heroicons/react/outline'
import { capitalizeFirstLetter } from '../../../lib/capitalizeFirstLetter'
import { statusIconColor } from '../Dashboard'

export const Status = ({ status }: { status: string }) => {
    let statusIcon = null

    const classNames = `flex-shrink-0 mr-1 h-5 w-5 ${statusIconColor(status)}`

    switch (status) {
        case 'rejected':
            statusIcon = <ExclamationCircleIcon className={classNames} aria-hidden="true" />
            break
        case 'new':
            statusIcon = <ExclamationCircleIcon className={classNames} aria-hidden="true" />
            break
        case 'acknowledged':
            statusIcon = <ThumbUpIcon className={classNames} aria-hidden="true" />
            break
        case 'completed':
            statusIcon = <CheckCircleIcon className={classNames} aria-hidden="true" />
            break
    }
    return (
        <div className="flex flex-row items-center">
            {statusIcon}
            <p className="mt-0 pt-0">{capitalizeFirstLetter(status)}</p>
        </div>
    )
}
