import { API } from '../lib/API'
import { useState } from 'react'
import { Logo } from './shared/Logo'
import { PRIMARY_BUTTON_COLOR } from '../colors'
import { MailIcon } from '@heroicons/react/outline'
import { ButtonLabelWithLoadingIcon } from './shared/ButtonLabelWithLoadingIcon'

export const ForgotPassword = () => {
    const [email, setEmail] = useState('test@example.com')
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)

    const emailInputOnChange = (event: any) => setEmail(event.target.value)

    const submitForm = async (event: any) => {
        setSubmitting(true)
        setSuccess(false)
        event.preventDefault()

        console.log('Submitting')

        await API.resetPassword(email)

        setSubmitting(false)
        // Prevent enumeration - always return 'success'
        setSuccess(true)
    }

    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <Logo className="mx-auto h-16 w-auto" />
                <h2 className="mt-1 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="h-8 text-green-600 text-center">
                        {success ? 'Check your email for further instructions' : null}
                    </div>
                    <form className="space-y-6" onSubmit={(event) => submitForm(event)}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none pl-10 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-primary-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={emailInputOnChange}
                                    placeholder="you@example.com"
                                />
                            </div>
                            <div className="mt-1"></div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={submitting}
                                className={
                                    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium ' +
                                    PRIMARY_BUTTON_COLOR
                                }
                            >
                                <div className="flex w-full">
                                    <ButtonLabelWithLoadingIcon loading={submitting}>
                                        Reset password
                                    </ButtonLabelWithLoadingIcon>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
